import type * as Core from '../../../core';
import { TypeGuard } from '../../TypeGuard';
import * as Slots from './Slots';
import type { Config } from '.';

export const from = ({
	adTypeProperties: pageProperties,
	intermingleItems,
	isSuccess,
	positionItems,
}: from.Options) => {
	if (!isSuccess) return null;
	if (!TypeGuard.Properties.Page.has(pageProperties)) return null;

	const slots = Array.from(Slots.from(...positionItems, ...intermingleItems));
	if (!slots.length) return null;

	// Discard prebid-related properties:
	const { prebidSettings, prebid, prebidBidderConfigs, ...config } = pageProperties.display;
	void prebidSettings, prebid, prebidBidderConfigs;

	return { ...config, slots } as const satisfies Config;
};

export namespace from {
	export type Options = Core.Config;
}
