/**
 * Using this template string function provides syntax
 * highlighting, linting, etc. for your editor.
 */
export function css(templateStrings: TemplateStringsArray, ...values: Array<string>) {
	return templateStrings.reduce(
		(accumulated, templateString, index) => `${accumulated}${templateString}${values[index] ?? ''}`,
		'',
	);
}
