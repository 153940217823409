import React from 'react';

export function whenAllChange<T extends React.DependencyList>(dependencies: T) {
	const prevDepsRef = React.useRef(dependencies);
	const allHaveChanged = dependencies.every(
		(dependency, index) => dependency !== prevDepsRef.current[index],
	);

	if (allHaveChanged) prevDepsRef.current = dependencies;

	return [...prevDepsRef.current] as const;
}
