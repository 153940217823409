import React from 'react';
import * as Core from '../../../../core';

export type State = typeof State.defaultValue;

export namespace State {
	export const defaultValue = {
		stickyPositions: {} as StickyPositions,
		wallpaper: {} as Wallpaper,
	} as const;

	export type StickyPositions = Partial<Record<string, boolean>>;

	export type Wallpaper =
		| Partial<Wallpaper.Options>
		| Partial<Wallpaper.Media.Options>
		| NonNullable<unknown>;

	export namespace Wallpaper {
		export type Options = Readonly<{
			imgUrl: string;
			clickUrl: string;
			impressionUrl: string;
		}>;
	}

	export namespace Wallpaper.Media {
		export type Options = Readonly<{
			mediaWallpaperUrl: string;
		}>;
	}
}

export namespace State {
	/**
	 * A hook that provides the display ads utilities.
	 */
	export function use(enabled: boolean) {
		const [state, setState] = React.useState<State>(State.defaultValue);

		const setStateIfEnabled = React.useCallback<typeof setState>(
			value => {
				if (enabled) setState(value);
			},
			[enabled, setState],
		);

		// Reset the state value:
		OnConfigChange.use(() => {
			Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'OnAdConfigChange', 'Reset state', {
				state,
			});

			setState(State.defaultValue);
		});

		return [state, setStateIfEnabled] as const;
	}

	export type Setter = ReturnType<typeof use>[1];
}

export namespace State.OnConfigChange {
	/**
	 * A hook that resets the display ads utilities when the ad config changes.
	 */
	export function use(resetUtilities: () => void) {
		const config = Core.Config.Context.use();
		Core.useBrowserLayoutEffect(() => () => resetUtilities(), [config]);
	}
}
