import type { Nominal } from '@smd/utilities';
import { isBem } from './isBem';
import type { BemBlock } from './makeBemBlock';
import { BemElement, makeBemElement } from './makeBemElement';
import { BlockCssClass, makeBlockClass } from './makeBlockClass';

export type ElementCssClass<
	TBlock extends BemBlock | BlockCssClass = BemBlock | BlockCssClass,
	TBemElement extends BemElement = BemElement,
> = Nominal<
	`${TBlock extends BemBlock ? BlockCssClass<TBlock> : TBlock}__${TBemElement}`,
	'CssElementClass'
>;

export function elementClassMaker<TBlockName extends string>(
	block: BemBlock<TBlockName> | BlockCssClass<BemBlock<TBlockName>>,
) {
	return <TElementName extends string>(name: TElementName | BemElement<TElementName>) => {
		return `${isBem(block) ? makeBlockClass(block) : block}__${makeBemElement(name)}` as ElementCssClass<
			BemBlock<TBlockName>,
			BemElement<TElementName>
		>;
	};
}
