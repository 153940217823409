import type { Nullable } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import type * as Core from '../../../core';
import * as AdManager from '../../../ad-manager';
import * as Adnuntius from '../../../adnuntius';
import * as AdSense from '../../../adsense';

export const Map = {
	[ShoAd.AdType.AdSenseForSearch]: AdSense.Service.Provider,
	[ShoAd.AdType.AdnuntiusDisplay]: Adnuntius.Service.Provider,
	[ShoAd.AdType.Display]: AdManager.Service.Provider,
} as const satisfies Map.Shape;

export type Map = typeof Map;

export namespace Map {
	export type Shape = {
		[TAdType in Core.AdType.Supported]: Nullable<
			React.ComponentType<{
				children: React.ReactNode;
				enabled: boolean;
			}>
		>;
	};

	export type Values = Map[keyof Map];
}
