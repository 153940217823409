import { Debouncer } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../core';
import { Api } from './Api';
import type { Context } from './Context';

export type State = Core.Service.Generic.State.Of<State.Active>;

export namespace State {
	export class Active extends Core.Service.Generic.State.Active<Active.Options> {
		static readonly from = (options: Active.Options) => new this(options);

		readonly activate: Context = this.#activate.bind(this);

		readonly #debouncer = new Debouncer<string>(16, this.abortSignal);
		readonly #queue = new Array<string>();

		protected override async executeSetup(abortSignal?: AbortSignal) {
			await this.#requestAds(this.#processQueue(), abortSignal);

			this.#runActivateLoop().catch((error: unknown) => {
				if (this.isDestroyed()) return;

				Core.log.error(
					'ADNUNTIUS',
					'AdUnits',
					'Activate',
					'Failed to request ads for queued ad units',
					{ error },
				);
			});
		}

		protected override async executeDestroy(abortSignal?: AbortSignal) {
			await Api.execute(function () {
				this.clearDivs();
			}, abortSignal);
		}

		#activate(id: string) {
			if (this.isDestroyed()) return;

			if (this.isSetUp()) this.#debouncer.add(id);
			else this.#queue.push(id);
		}

		async #runActivateLoop() {
			for await (const idsChunk of this.#debouncer) {
				if (this.isDestroyed()) return;

				this.#queue.push(...idsChunk);
				await this.#requestAds(this.#processQueue(), this.abortSignal);
			}
		}

		async #requestAds([...adUnits]: Iterable<Api.Config.AdUnit>, abortSignal?: AbortSignal) {
			if (!adUnits.length) {
				Core.log.warn(
					'REQUEST',
					ShoAd.AdType.AdnuntiusDisplay,
					'AdUnits',
					'Aborted due to no ad units registered',
				);
				return;
			}

			const config = { ...this.options, adUnits } as const satisfies Api.Config;

			await Api.execute(function () {
				Core.log('REQUEST', ShoAd.AdType.AdnuntiusDisplay, 'AdUnits', 'Initiated', { config });
				this.request(config);
			}, abortSignal);
		}

		*#processQueue() {
			const { options } = this;

			while (this.#queue.length) {
				const targetId = this.#queue.shift();
				const adUnit = options.adUnits.find(adUnit => adUnit.targetId === targetId);

				if (!adUnit) {
					Core.log.error('ADNUNTIUS', 'AdUnits', 'Config', 'Ad unit not found', {
						targetId,
						options,
					});
					continue;
				}

				yield adUnit;
			}
		}
	}

	export namespace Active {
		export type Options = Readonly<Api.Config>;
	}
}
