import { useQuery } from '@tanstack/react-query';
import { getUserDetails as getUserDetailsFrontpage } from 'api/frontpage';
import { getUserDetails as getUserDetailsSearch } from 'api/search';
import React from 'react';
import { useSyncToDataLayer } from './useSyncToDataLayer';
import {usePathname} from "next/navigation";

export const useUser = () => {
  const path = usePathname()
  
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: () => path === "/" ? getUserDetailsFrontpage() : getUserDetailsSearch(),
    refetchInterval: 1000 * 60 * 10, // Check every 10 minutes,
    refetchOnWindowFocus: true, // If user logged out in different tab
    refetchOnReconnect: true, // If session expired while being offline
    keepPreviousData: true, // Keep data from the backend
    staleTime: 1000 * 10, // Check at maximum after 10 seconds
  });

  // Make the user-specific data available on the global dataLayer:
  useSyncToDataLayer(
      data?.dataLayer,
      Symbol.for('DataLayer:Meridian:UserSpecificData')
  );

  const isAuthenticated = React.useMemo(
      () => data?.userProfile?.isAuthenticated ?? false,
      [data?.userProfile?.isAuthenticated]
  );

  return {
    user: data,
    isAuthenticated,
  };
};
