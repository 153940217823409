import type { Nominal } from '@smd/utilities';
import type { ElementCssClass } from './elementClassMaker';
import { isBem } from './isBem';
import type { BemBlock } from './makeBemBlock';
import type { BemElement } from './makeBemElement';
import { BemModifier, makeBemModifier } from './makeBemModifier';
import { BlockCssClass, makeBlockClass } from './makeBlockClass';

export function modifierClassMaker<
	TBlockName extends string,
	TElementName extends string,
	TBlockOrElement extends
		| BemBlock<TBlockName>
		| BlockCssClass<BemBlock<TBlockName>>
		| ElementCssClass<
				BemBlock<TBlockName> | BlockCssClass<BemBlock<TBlockName>>,
				BemElement<TElementName>
		  >,
>(blockOrElement: TBlockOrElement) {
	return <TName extends string>(name: TName | BemModifier<TName>) => {
		// eslint-disable-next-line @typescript-eslint/no-base-to-string
		return `${isBem(blockOrElement) ? makeBlockClass(blockOrElement) : blockOrElement}--${makeBemModifier(
			name,
		)}` as ModifierCssClass<TBlockOrElement, BemModifier<TName>>;
	};
}

export type ModifierCssClass<
	TBlockOrElement extends BemBlock | BlockCssClass | ElementCssClass =
		| BemBlock
		| BlockCssClass
		| ElementCssClass,
	TBemModifier extends BemModifier = BemModifier,
> = Nominal<
	`${TBlockOrElement extends BemBlock
		? BlockCssClass<TBlockOrElement>
		: TBlockOrElement}--${TBemModifier}`,
	'CssModifierClass'
>;
