import { ExtendedContext } from '../ExtendedContext';
import * as namespace from './.namespace';
import type { Config } from '.';

/** The empty default config, can be reused for stable object reference */
const emptyValue = {
	adTypeProperties: {},
	intermingleItems: [],
	positionItems: [],
	columnsCount: null,
	isEmpty: true,
	isFailed: false,
	isCompleted: false,
	isSuccess: false,
} as const satisfies Config.Empty;

/** A failed config, can be reused for stable object reference */
const failedValue = {
	adTypeProperties: {},
	intermingleItems: [],
	positionItems: [],
	columnsCount: null,
	isEmpty: true,
	isFailed: true,
	isCompleted: true,
	isSuccess: false,
} as const satisfies Config.Failed;

export const Context = Object.assign(ExtendedContext.of<Config>(emptyValue, namespace.Config), {
	emptyValue,
	failedValue,
} as const);
