import type { Nominal } from '@smd/utilities';
import { CSS_NAMESPACE } from './constants';
import { BemBlock, makeBemBlock } from './makeBemBlock';

export type BlockCssClass<TBemBlock extends BemBlock = BemBlock> = Nominal<
	`${typeof CSS_NAMESPACE}-${TBemBlock}`,
	'CssBlockClass'
>;

export function makeBlockClass<TName extends string>(name: TName | BemBlock<TName>) {
	return `${CSS_NAMESPACE}-${makeBemBlock(name)}` as BlockCssClass<BemBlock<TName>>;
}
