import { canUseDOM, type Nullable } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import type * as Tracking from '../../../tracking';
import { TypeGuard } from '../../TypeGuard';
import type { Api } from '../Api';

export function* from(
	trackingManager: Nullable<Tracking.Manager>,
	...adSlots: ReadonlyArray<ShoAd.IAdSlot>
) {
	const adType = ShoAd.AdType.AdSenseForSearch;
	const fallbackWidth = canUseDOM() ? window.document.body.clientWidth : NaN;

	for (const adSlot of adSlots) {
		if (!TypeGuard.Slot.is(adSlot)) continue;

		const { width = fallbackWidth, ...unitLevelParameters } = adSlot.item.adsenseForSearch;

		yield {
			...unitLevelParameters,
			width,

			adLoadedCallback(container, loadIsSuccessful) {
				trackingManager?.handleEvent(
					{
						adType,
						eventType: loadIsSuccessful
							? ShoAd.AdCountEvent.Impression
							: ShoAd.AdCountEvent.UnfilledImpression,
						container,
					},
					adSlot.item,
				);
			},
		} as const satisfies Api.Parameters.Block;
	}
}
