import React from 'react';
import * as Prebid from '../../../prebid';
import type * as Core from '../../../core';
import * as namespace from './.namespace';
import { Configs } from './Configs';
import type { Options } from './Options';

/**
 * A component that compiles and renders a tree of service providers based
 * on the provided supported ad types.
 */
export function Compiled({ config, children }: Compiled.Props) {
	return (
		<Prebid.Service.Provider enabled={config.enabled}>
			{Compiled.use(config, children)}
		</Prebid.Service.Provider>
	);
}

export namespace Compiled {
	export const displayName = namespace.Providers.nameof({ Compiled });

	export type Props = {
		config: Config;
		children: React.ReactNode;
	};

	export type Config = {
		enabled: boolean;
		supportedAdTypes: Array<Core.AdType.Supported>;
		supportedAdTypeOptions: Options;
	};

	/**
	 * Recursively creates a tree of service providers based on their
	 * configurations.
	 */
	export function from(
		configs: Configs,
		enabled: boolean,
		baseChildren: React.ReactNode,
	): React.JSX.Element {
		const [config, ...restConfigs] = configs;

		// Return the children if there are no more configurations:
		if (!config) return <>{baseChildren}</>;

		const children = from(restConfigs, enabled, baseChildren);
		const [ServiceProvider, props] = config;

		const serviceProviderProps = {
			...props,
			enabled,
			children,
		};

		return <ServiceProvider {...serviceProviderProps} />;
	}

	/**
	 * A hook that returns a tree of service providers based on the supported
	 * ad types that's been provided.
	 */
	export function use(config: Config, children: React.ReactNode) {
		return from(
			Configs.use(config.supportedAdTypes, config.supportedAdTypeOptions),
			config.enabled,
			children,
		);
	}
}
