import React from 'react';
import { useBrowserLayoutEffect } from './useBrowserLayoutEffect';

export function useIsMounted() {
	const isMountedRef = React.useRef(false);

	useBrowserLayoutEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	return React.useCallback(() => isMountedRef.current, []);
}
