import styles from './Footer.module.scss';
import Facebook from '@/icons/Facebook';
import Instagram from '@/icons/Instagram';
import Twitter from '@/icons/Twitter';
import Navigation from '@/molecules/Navigation';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <Navigation isLoggedIn={false} />
        <div className={styles.bottom}>
          <span className={styles.copyright}>
            &copy; {new Date().getFullYear()} - Schibsted
          </span>
          <div className={styles.social}>
            <span>Find os på </span>
            <a
              className="gtm-track"
              href="https://www.facebook.com/bilbasen.dk"
              target="_blank"
              rel="noopener noreferrer"
              data-track-action="FacebookClick"
            >
              <Facebook height="2rem" />
            </a>
            <a
              className="gtm-track"
              href="https://twitter.com/BilBasen"
              target="_blank"
              rel="noopener noreferrer"
              data-track-action="TwitterClick"
            >
              <Twitter height="2rem" />
            </a>
            <a
              className="gtm-track"
              href="https://www.instagram.com/bilbasen/"
              target="_blank"
              rel="noopener noreferrer"
              data-track-action="InstagramClick"
            >
              <Instagram height="2rem" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
