export const Menu = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <rect x="1.75781" y="4.5" width="21" height="2" rx="1" />
    <rect x="1.75781" y="10.5" width="21" height="2" rx="1" />
    <rect x="1.75781" y="16.5" width="21" height="2" rx="1" />
  </svg>
);

Menu.displayName = 'Menu';
export default Menu;
