export const Twitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h32v32H0V0Zm23.64 10.45A8.222 8.222 0 0 0 26 9.8a8.372 8.372 0 0 1-2.01 2.11v.53a11.67 11.67 0 0 1-18 9.83 8.26 8.26 0 0 0 6.07-1.7 4.11 4.11 0 0 1-3.83-2.85c.254.048.512.072.77.07a4.11 4.11 0 0 0 1.08-.14 4.11 4.11 0 0 1-3.29-4v-.05a4.08 4.08 0 0 0 1.86.51 4.11 4.11 0 0 1-1.27-5.48 11.64 11.64 0 0 0 8.46 4.29 4.149 4.149 0 0 1-.11-.93 4.11 4.11 0 0 1 7.1-2.81 8.2 8.2 0 0 0 2.61-1 4.11 4.11 0 0 1-1.8 2.27Z"
    />
  </svg>
);

Twitter.displayName = 'Twitter';
export default Twitter;
