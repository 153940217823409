import './index.css';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { equals } from 'ramda';
import { buildCssVariableProperties, css, propertiesToCss } from '../../../Styles';
import * as Styles from '../Styles';
import * as namespace from '../.namespace';

/** Controls the offset of the midscroll element directly. */
export const Controlled = React.memo<Controlled.Props>(function Controlled({ offset }) {
	if (offset === null) return null;

	return (
		<Helmet>
			<style className={Styles.element}>{css`
				:root {
					${cssProperties({ offset: `${offset}px` })}
				}
			`}</style>
		</Helmet>
	);
}, equals);

Controlled.displayName = namespace.Midscroll.nameof({ Controlled });

export namespace Controlled {
	export type Props = {
		/** The offset in pixels to apply to the midscroll element. */
		offset: number | null;
	};
}

function cssProperties(...args: Parameters<typeof cssProperties.build>) {
	return propertiesToCss(cssProperties.build(...args));
}

namespace cssProperties {
	export const build = buildCssVariableProperties(Styles.element);
}
