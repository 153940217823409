import type React from 'react';
import type { IsNever } from '@smd/utilities';
import type * as Core from '../../../core';
import type { Map } from './Map';

export type Options = {
	[TAdType in Core.AdType.Supported as Options.Name<TAdType>]?: Map[TAdType] extends React.ComponentType<
		infer TProps
	>
		? IsNever<keyof Omit<TProps, Options.Omitted>> extends true
			? Options.Default
			: Omit<TProps, Options.Omitted>
		: Options.Default;
};

export namespace Options {
	export type Default = true;

	export type Omitted = 'children' | 'enabled';

	export type Name<TSupportedAdType extends Core.AdType.Supported> = `with${TSupportedAdType}`;

	export namespace Name {
		/** Returns the name of the option for the given ad type. */
		export function from<TSupportedAdType extends Core.AdType.Supported>(adType: TSupportedAdType) {
			return `with${adType}` as const satisfies Name<TSupportedAdType>;
		}
	}
}
