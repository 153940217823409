import type { Nullable } from '@smd/utilities';
import { Api } from '../../Api';
import { EventForwarder } from './EventForwarder';
import * as Parameters from './Parameters';

export async function* run(
	configId: string,
	allowedDivIds: ReadonlyArray<string>,
	...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
) {
	for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

	const eventForwarder = new EventForwarder(...abortSignals);

	yield* await Api.execute(
		function () {
			try {
				this.loadPrebid(Parameters.from(configId, allowedDivIds, eventForwarder));
			} catch (error) {
				eventForwarder.destroy();
				throw error;
			}

			return eventForwarder;
		},
		...abortSignals,
	);
}
