import type * as ShoAd from '@smd/sho-advertising-typings';
import type { TrackingPlugin } from '@smd/tracking';
import { log } from '../../core';

export class Console implements TrackingPlugin<ShoAd.TrackingEvent, [ShoAd.AdSlotProperties?]> {
	async handleEvent(event: ShoAd.TrackingEvent, properties?: ShoAd.AdSlotProperties) {
		log('IMPRESSION', event.adType, event.container, event.eventType, { properties });
		return Promise.resolve();
	}
}
