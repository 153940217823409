import { LazyPromise, type Nullable, Poller, ensure } from '@smd/utilities';
import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import { hasGoogleConsent } from '@smd/cmp-sourcepoint';
import * as Fallback from './Fallback';
import * as Validators from './Validators';

let resolved = false;

let tempResult: readonly [LazyPromise<MeridianDataLayerValue>, AbortController?] | null = null;

export function get(
	getMeridianDataLayer: () => Nullable<MeridianDataLayerValue>,
	timeout = 5000,
	interval = 16,
) {
	// If we've successfully resolved the dataLayer asynchronously before, then
	// we can just skip all of the complex async logic and perform a quick
	// synchronous operation:
	if (resolved) {
		return [
			new LazyPromise<MeridianDataLayerValue>(resolve => resolve(ensure(getMeridianDataLayer()))),
		] as const;
	}

	// If we've already started the async logic to resolve the dataLayer, then we
	// can just reuse the promise and abort controller that we've already created.
	// This is useful for when we have multiple components that are trying to
	// resolve the dataLayer at the same time:
	if (tempResult) return tempResult;

	const internalAbortController =
		typeof AbortController !== 'undefined' ? new AbortController() : undefined;

	const augmentedAbortController = internalAbortController
		? ({
				abort(reason) {
					if (!resolved) internalAbortController.abort(reason);
				},
				signal: internalAbortController.signal,
			} as AbortController)
		: undefined;

	// If we haven't resolved the dataLayer asynchronously before, then we need to
	// perform the complex async logic to resolve it. We store the promise in a
	// global variable, so that we can reuse it if it's already been invoked:
	const dataLayerLazyPromise = new LazyPromise<MeridianDataLayerValue>((resolve, reject) => {
		get
			.eager(getMeridianDataLayer, timeout, interval, augmentedAbortController?.signal)
			.then(resolve, reject);
	});

	return (tempResult = augmentedAbortController
		? ([dataLayerLazyPromise, augmentedAbortController] as const)
		: ([dataLayerLazyPromise] as const));
}

export namespace get {
	export async function eager(
		getMeridianDataLayer: () => Nullable<MeridianDataLayerValue>,
		timeout = 5000,
		interval = 16,
		abortSignal?: AbortSignal,
	) {
		try {
			const startTime = Date.now();

			const noConsentWhenTimedOutOrAbortedPromise = new Promise<false>(resolveConsent => {
				const abort = () => {
					resolveConsent(false);
					abortSignal?.removeEventListener('abort', abort);
				};

				setTimeout(abort, timeout);
				abortSignal?.addEventListener('abort', abort);
			});

			const consented = await Promise.race([
				hasGoogleConsent(),
				noConsentWhenTimedOutOrAbortedPromise,
			] as const);

			const passedTime = Date.now() - startTime;
			const remainingTime = timeout - passedTime;
			const maxRetries = Math.max(1, Math.floor(remainingTime / interval));

			const dataLayer = await Poller.for(getMeridianDataLayer)
				.addValidator(Validators.ConsentAware.from(consented))
				.setInterval(interval)
				.setMaxRetries(maxRetries)
				.resolve(abortSignal);

			// Set the resolved flag to true, so that we can skip the async logic onwards:
			resolved = true;

			return dataLayer;
		} catch (error) {
			if (abortSignal?.aborted) throw error;

			// Try getting basic/fallback dataLayer:
			const fallbackDataLayer = Fallback.get();
			if (fallbackDataLayer) return fallbackDataLayer;

			throw error;
		} finally {
			// Reset the tempResult to null, to enable retrying the async logic:
			tempResult = null;
		}
	}
}
