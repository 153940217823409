import { Base } from './Base';

export class Tracking extends Base {
	readonly states = Tracking.States.map(State => new State(this.options));

	protected override async executeSetup(abortSignal?: AbortSignal) {
		await Promise.all(this.states.map(state => state.setup(abortSignal)));
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await Promise.all(this.states.map(state => state.destroy(abortSignal)));
	}
}

import * as _Base from './Base';
import * as _Custom from './Custom';
import * as _Prebid from './Prebid';

export namespace Tracking {
	export import Base = _Base.Base;
	export import Custom = _Custom.Custom;
	export import Prebid = _Prebid.Prebid;
}

export namespace Tracking {
	export const States = [
		Tracking.Custom,
		Tracking.Prebid,
	] as const satisfies ReadonlyArray<Tracking.Base>;
}
