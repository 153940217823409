import * as Core from '../../../../core';
import { Base } from './Base';

export class Custom extends Base {
	protected override executeSetup(abortSignal?: AbortSignal) {
		if (abortSignal?.aborted) return;

		for (const [type, listener] of Custom.Event.Binding.entries) {
			try {
				window.addEventListener(type, listener);
			} catch (error) {
				Core.log.error(
					'PREBID',
					'State',
					'CustomEventLogging',
					'Failed setup of custom event listener',
					{ type, listener, error },
				);
			}
		}
	}

	protected override executeDestroy() {
		for (const [type, listener] of Custom.Event.Binding.entries) {
			try {
				window.removeEventListener(type, listener);
			} catch (error) {
				Core.log.error(
					'PREBID',
					'State',
					'CustomEventLogging',
					'Failed destroy of custom event listener',
					{ type, listener, error },
				);
			}
		}
	}
}

export namespace Custom.Event {
	export namespace Targeting {
		export const type = 'nmp.ads.targeting';

		export function log(event: Event) {
			if (!(event instanceof CustomEvent)) return;
			const targeting = event.detail as unknown;

			if (typeof targeting !== 'object') return;
			if (!targeting) return;

			Core.log('PREBID', 'CustomEvent', event.type, 'Custom targeting was applied', {
				targeting,
			});
		}

		export namespace Error {
			export const type = 'nmp.ads.targeting.error';

			export function log(event: Event) {
				if (!(event instanceof CustomEvent)) return;
				const error = event.detail as unknown;

				if (typeof error !== 'object') return;
				if (!error) return;

				Core.log.error('PREBID', 'CustomEvent', event.type, 'Failed to apply custom targeting', {
					error,
				});
			}
		}
	}

	export type Binding = Readonly<Parameters<Window['addEventListener' | 'removeEventListener']>>;

	export namespace Binding {
		export const entries = [
			[Targeting.type, Targeting.log],
			[Targeting.Error.type, Targeting.Error.log],
		] as const satisfies ReadonlyArray<Binding>;
	}
}
