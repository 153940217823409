import * as Core from '../../core';
export * as Display from './Display';
import * as namespace from './.namespace';

const defaultValue = {
	/** Enables ad fetching and rendering. */
	enable(this: void): void {
		throw new Error('Ads.Utilities.enable called while Ads.Provider is unmounted!');
	},

	/** Based on the current dataLayer, refreshes all ads on the page. */
	refresh(this: void): void {
		throw new Error('Ads.Utilities.refresh called while Ads.Provider is unmounted!');
	},
} as const;

const currentValue = { ...defaultValue };

export const Context = Object.assign(Core.ExtendedContext.of(defaultValue, namespace.Utilities), {
	currentValue,
} as const);

/** Advertising utilities. */
export type Context = typeof defaultValue;
