import type { Nullable } from '@smd/utilities';
import type * as Core from '../../../core';
import type * as Tracking from '../../../tracking';
import { TypeGuard } from '../../TypeGuard';
import type { State } from '../State';
import * as Blocks from './Blocks';

export function from({
	trackingManager,
	adTypeProperties: pageProperties,
	intermingleItems,
	isSuccess,
	positionItems,
}: from.Options) {
	if (!isSuccess) return null;
	if (!TypeGuard.Properties.Page.has(pageProperties)) return null;

	const blocks = Array.from(Blocks.from(trackingManager, ...positionItems, ...intermingleItems));

	if (!blocks.length) return null;

	// Backend still returns styleIds, which makes AdSense unhappy, thus we omit it:
	const { styleIds, ...pageLevelParameters } = pageProperties.adsenseForSearch;
	void styleIds;

	return { blocks, pageLevelParameters } as const satisfies State.Active.Options;
}

export namespace from {
	export type Options = Core.Config & {
		trackingManager: Nullable<Tracking.Manager>;
	};
}
