import { noop } from '@smd/utilities';
import * as Core from '../../../../core';
import { Api } from '../../Api';
import { Base } from './Base';

export class Prebid extends Base {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			const { pbjs } = this.getInstance();

			for (const [type, handler] of Prebid.Event.Binding.entries) {
				try {
					pbjs.onEvent(type, handler);
				} catch (error) {
					Core.log.error(
						'PREBID',
						'State',
						'EventLogging',
						'Failed setup of prebid event handler',
						{ type, handler, error },
					);
				}
			}
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			const { pbjs } = this.getInstance();

			for (const [type, handler] of Prebid.Event.Binding.entries) {
				try {
					pbjs.offEvent(type, handler);
				} catch (error) {
					Core.log.error(
						'PREBID',
						'State',
						'EventLogging',
						'Failed destroy of prebid event handler',
						{ type, handler, error },
					);
				}
			}
		}, abortSignal);
	}
}

export namespace Prebid.Event {
	export namespace Bid {
		export async function getSlotElementId(bid: Pbjs.Bid.Args.Common, abortSignal?: AbortSignal) {
			return await Api.execute(function () {
				return this.getAdUnitInstanceByBid(bid).slot.getSlotElementId();
			}, abortSignal);
		}

		export namespace Response {
			export const type = 'bidResponse';

			export function log(bid: Pbjs.Bid.Args.Common) {
				getSlotElementId(bid).then(
					slotElementId =>
						Core.log(
							'PREBID',
							bid.bidderCode,
							slotElementId,
							`BID: ${bid.currency} ${bid.pbCg} (${bid.cpm})`,
							bid,
						),
					noop,
				);
			}
		}

		export namespace Won {
			export const type = 'bidWon';

			export function log(bid: Pbjs.Bid.Args.Common) {
				getSlotElementId(bid).then(
					slotElementId => Core.log('PREBID', bid.bidderCode, slotElementId, '🏆 BidWon 🏆', bid),
					noop,
				);
			}
		}
	}

	export type Binding = Readonly<Parameters<Pbjs['onEvent' | 'offEvent']>>;

	export namespace Binding {
		export const entries = [
			[Bid.Response.type, Bid.Response.log],
			[Bid.Won.type, Bid.Won.log],
		] as const satisfies ReadonlyArray<Binding>;
	}
}
