import React, { useMemo } from 'react';
import { as, ensure } from '@smd/utilities';
import type { AdType } from '../AdType';
import type { Position } from '../Position';
import { usePositionAdSlot } from './usePositionAdSlot';
import type { PropsMapper } from './PropsMapper';
import type { AdPositionComponent } from './AdPositionComponent';
import type { Builder } from './Builder';

/** Helps easily create Position components. */
export namespace AdPositionBuilder {
	export function from<TAdType extends AdType.Supported>(
		positionSelector: Position.Selector<TAdType>,
	) {
		const named: Array<string> = [];
		const propsMappers: Array<PropsMapper<never, TAdType, HTMLElement, never>> = [];
		let AdPositionComponent: AdPositionComponent<string, TAdType, HTMLElement, never> | null = null;

		const builder: Builder<TAdType, never, never, HTMLElement, never> = {
			asNamed(name, ...names) {
				named.push(name, ...names);
				return as(builder);
			},

			setElementType() {
				return builder;
			},

			extendPropsType() {
				return builder;
			},

			mapProps(propsMapper) {
				propsMappers.push(propsMapper);
				return builder;
			},

			withComponent(component) {
				AdPositionComponent = component;
				return builder;
			},

			build() {
				/**
				 * This usage of {@link ensure} should never throw since we're
				 * guaranteed through type safety of {@link builder} that when
				 * {@link builder.build} is called that {@link AdPositionComponent}
				 * is already set through {@link builder.withComponent}.
				 */
				const EnsuredAdPositionComponent = ensure(AdPositionComponent);

				return function BuiltAdPosition(props) {
					const { name, ...restProps } = props as typeof props & {
						name?: string;
					};

					/**
					 * If {@link name} is unset we know through type safety
					 * that there is exactly 1 name in {@link named}.
					 */
					const positionName = name ?? ensure(named[0]);

					const position = usePositionAdSlot(positionName, positionSelector);

					const mappedProps = useMemo(() => {
						if (!position) return null;

						return propsMappers.reduce(
							(accumulatedProps, mapper) =>
								mapper(position as Parameters<typeof mapper>[0], accumulatedProps),
							restProps,
						);
					}, [position, restProps]);

					if (!position || !mappedProps) return null;

					return <EnsuredAdPositionComponent adSlot={position} props={mappedProps} />;
				};
			},
		};

		return builder as Builder<TAdType>;
	}
}
