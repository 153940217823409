import React from 'react';
import type * as Core from '../../../core';
import { Options } from './Options';
import type { Map } from './Map';
import { get } from './get';

export type Configs = ReturnType<typeof Configs.use>;

export namespace Configs {
	/**
	 * Generates a provider configuration for each supported ad type.
	 */
	export function* from(adTypes: Array<Core.AdType.Supported>, options: Options) {
		const providers = new Set<Map.Values>();

		for (const adType of adTypes) {
			const provider = get(adType);
			const props = options[Options.Name.from(adType)];

			if (!props) continue;
			if (providers.has(provider)) continue;

			yield typeof props === 'object' ? ([provider, props] as const) : ([provider] as const);
		}
	}

	/**
	 * A hook that provides a memoized set of provider configurations for the
	 * given ad types and options.
	 */
	export function use(adTypes: Array<Core.AdType.Supported>, options: Options) {
		return React.useMemo(() => Array.from(from(adTypes, options)), [adTypes, options]);
	}
}
