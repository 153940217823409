import type * as Core from '../../../core';

export type State = Core.Service.Generic.State.Of<State.Active>;

import * as _Active from './Active';
import * as _LazyLoad from './LazyLoad';
import * as _Ppid from './Ppid';
import * as _SizeMapping from './SizeMapping';
import * as _Slot from './Slot';
import * as _Tracking from './Tracking';

export namespace State {
	export import Active = _Active.Active;
	export import LazyLoad = _LazyLoad.LazyLoad;
	export import Ppid = _Ppid.Ppid;
	export import SizeMapping = _SizeMapping.SizeMapping;
	export import Slot = _Slot.Slot;
	export import Tracking = _Tracking.Tracking;
}
