import { Debouncer } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../core';
import { Api } from './Api';
import type { Context } from './Context';

export type State = Core.Service.Generic.State.Of<State.Active>;

export namespace State {
	export class Active extends Core.Service.Generic.State.Active<Active.Options> {
		static readonly from = (options: Active.Options) => new this(options);

		readonly activate: Context = this.#activate.bind(this);

		readonly #debouncer = new Debouncer<string>(16, this.abortSignal);
		readonly #queue = new Array<string>();

		protected override async executeSetup(abortSignal?: AbortSignal) {
			await this.#requestAds(this.#processQueue(), abortSignal);

			this.#runActivateLoop().catch((error: unknown) => {
				if (this.isDestroyed()) return;

				Core.log.error('ADSENSE', 'Blocks', 'Activate', 'Failed to request ads for queued blocks', {
					error,
				});
			});
		}

		protected override executeDestroy() {
			// Do nothing, since AdSense does not provide a way to clear ads
		}

		#activate(id: string) {
			if (this.isDestroyed()) return;

			if (this.isSetUp()) this.#debouncer.add(id);
			else this.#queue.push(id);
		}

		async #runActivateLoop() {
			for await (const idsChunk of this.#debouncer) {
				if (this.isDestroyed()) return;

				this.#queue.push(...idsChunk);
				await this.#requestAds(this.#processQueue(), this.abortSignal);
			}
		}

		async #requestAds(
			[block, ...blocks]: Iterable<Api.Parameters.Block>,
			abortSignal?: AbortSignal,
		) {
			if (!block) {
				Core.log(
					'REQUEST',
					ShoAd.AdType.AdSenseForSearch,
					'Blocks',
					'Aborted due to no blocks registered',
				);
				return;
			}

			const args = [
				'ads',
				this.options.pageLevelParameters,
				block,
				...blocks,
			] as const satisfies Api.Parameters;

			await Api.execute(function () {
				this(...args);
			}, abortSignal);
		}

		*#processQueue() {
			const { options } = this;

			while (this.#queue.length) {
				const container = this.#queue.shift();
				const block = options.blocks.find(block => block.container === container);

				if (!block) {
					Core.log.error('ADSENSE', 'Blocks', 'Config', 'Block not found', { container, options });
					continue;
				}

				yield block;
			}
		}
	}

	export namespace Active {
		export type Options = Readonly<{
			pageLevelParameters: Api.Parameters.PageLevelParameters;
			blocks: ReadonlyArray<Api.Parameters.Block>;
		}>;
	}
}
