import { Lazy } from '@smd/utilities';
import type * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../../core';
import { Api } from '../Api';
import * as Auction from './Auction';
import { Tracking } from './Tracking';
import * as namespace from './.namespace';

export class Active extends Core.Service.Generic.State.Active<Active.Options> {
	static readonly from = (options: Active.Options) => new this(options);

	readonly #tracking = new Tracking(this.options);
	readonly #config = Lazy.of(() => this.#getConfig());

	constructor(options: Active.Options) {
		super(options);
		Core.log('CONFIGURATION', 'PREBID', 'setupPrebid', 'Received configuration', options);
	}

	async *runAuction(allowedDivIds: ReadonlyArray<string>, abortSignal?: AbortSignal) {
		const abortSignals = [this.abortSignal, abortSignal] as const;
		for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

		const { configId } = await this.#config();

		yield* Auction.run(configId, allowedDivIds, ...abortSignals);
	}

	protected override async executeSetup(abortSignal?: AbortSignal) {
		await this.#tracking.setup(abortSignal);

		const { ortb2 } = this.options;

		await Api.execute(function () {
			if (ortb2) this.addPrebidConfig({ ortb2 });
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await this.#tracking.destroy(abortSignal);

		const { ortb2 } = this.options;

		await Api.execute(function () {
			if (ortb2) this.addPrebidConfig({ ortb2: {} });
		}, abortSignal);
	}

	async #getConfig() {
		const configurationType = 'consented';

		const config = await Api.execute(function () {
			const [config] = this.getConfigs()
				.filter(config => !('parentConfigId' in config && config.parentConfigId))
				.filter(({ data }) => data.configurationType === configurationType)
				.sort(
					({ data: one }, { data: other }) =>
						(one.priority ?? Number.POSITIVE_INFINITY) -
						(other.priority ?? Number.POSITIVE_INFINITY),
				);

			return config;
		}, this.abortSignal);

		if (!config) throw new Active.MissingConfigurationError(configurationType);

		return config;
	}
}

export namespace Active {
	export type Options = Readonly<{ ortb2?: ShoAd.OpenRTB2Targeting }>;

	export class MissingConfigurationError extends Error {
		static {
			this.prototype.name = namespace.State.nameof({ Active }, { MissingConfigurationError });
		}

		constructor(configurationType: Api.Config.Common.Data.ConfigurationType) {
			super(`No configuration found for type '${configurationType}'!`);
		}
	}
}
