export const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={17}
    width={16}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="m5.5 14.25 5.5-5.5-5.5-5.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ChevronRight.displayName = 'ChevronRight';
export default ChevronRight;
