import React from 'react';
import * as Core from '../../../../../core';
import type { Hook } from './Hook';

/**
 * A hook that creates the function that sets the wallpaper.
 */
export const use: Hook<Setter> = setState =>
	React.useCallback(
		(imgUrl, clickUrl, impressionUrl) => {
			const wallpaper = { imgUrl, clickUrl, impressionUrl } as const;

			Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Api', 'Wallpaper', { wallpaper });

			setState(prevState => ({ ...prevState, wallpaper }));
		},
		[setState],
	);

export type Setter = {
	(imgUrl?: string, clickUrl?: string, impressionUrl?: string): void;
};

export namespace Media {
	/**
	 * A hook that creates the function that sets the media wallpaper.
	 */
	export const use: Hook<Setter> = setState =>
		React.useCallback(
			mediaWallpaperUrl => {
				const wallpaper = { mediaWallpaperUrl } as const;

				Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Api', 'Wallpaper.Media', {
					wallpaper,
				});

				setState(prevState => ({ ...prevState, wallpaper }));
			},
			[setState],
		);

	export type Setter = {
		(mediaWallpaperUrl: string): void;
	};
}
