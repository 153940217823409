/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import data from './data/navigation.json';
import styles from './Navigation.module.scss';
import { useUser } from '@/hooks/useUser';

interface INavigation {
  title: string;
  items: INavigationItem[];
}

interface INavigationItem {
  label: string;
  href: string;
  trackAction?: string;
  trackLabel?: string;
  external?: boolean;
  conditions?: Condition[];
  attributes?: Record<string, string>;
}

type Condition = 'loggedIn' | 'notLoggedIn';

interface Props {
  isLoggedIn: boolean;
}

export const Navigation = React.memo<Props>(({ isLoggedIn }) => {
  const { isAuthenticated } = useUser();

  const satisfiesConditions = (conditions?: Condition[]) => {
    if (!conditions) return true;

    return conditions.reduce((status, condition) => {
      switch (condition) {
        case 'loggedIn':
          return isAuthenticated;
        case 'notLoggedIn':
          return !isAuthenticated;
        default:
          return status;
      }
    }, false);
  };

  return (
    <div className={styles.columns}>
      {(data as INavigation[]).map((menu, index) => (
        <div key={index} className={styles.column}>
          <h4>{menu.title}</h4>
          <ul>
            {menu.items
              .filter((menuItem) => satisfiesConditions(menuItem.conditions))
              .map((menuItem, itemIndex) => (
                <li key={index + '-' + itemIndex}>
                  <a
                    href={menuItem.href}
                    rel={menuItem.external ? 'noopener bookmark' : 'bookmark'}
                    className="gtm-track"
                    data-track-action={menuItem.trackAction}
                    data-track-label={menuItem.trackLabel}
                    target={menuItem.external ? '_blank' : '_self'}
                    {...menuItem.attributes}
                  >
                    {menuItem.label}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
});

Navigation.displayName = 'Navigation';

export default Navigation;
