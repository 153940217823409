import './index.css';
import React from 'react';
import * as Core from '../../../core';
import * as Display from '../../Display';
import * as Styles from '../../Styles';
import * as namespace from '../.namespace';

const blockClass = Core.makeBlockClass('top');
const adSlotElementClass = Core.elementClassMaker(blockClass)(Styles.slotElement);

export const Top = Display.Position.Builder()
	.asNamed(Core.AdPosition.Top)
	.withComponent(function Top({ adSlot, props: { className, ...props } }) {
		return (
			<Display.Slot
				{...props}
				properties={adSlot.item}
				className={Core.injectClass(adSlotElementClass, className)}
			/>
		);
	})
	.build();

Top.displayName = namespace.Position.nameof({ Top });
