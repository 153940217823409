import React from 'react';
import * as Core from '../../core';
import type * as Services from '../Services';
import * as Display from './Display';
import * as namespace from './.namespace';
import { Context } from './Context';

/**
 * Provides advertising utilities to child components and the global
 * context (for usages outside of React).
 */
export function Provider({
	children,
	config: {
		supportedAdTypeOptions: { withAdnuntiusDisplay, withDisplay },
		enable,
		refresh,
	},
}: Provider.Props) {
	// Memoize the utilities object to avoid unnecessary re-renders:
	const utilities = React.useMemo(() => ({ enable, refresh }) as const, [enable, refresh]);

	// Update the globally accessible utilities object when the utilities object changes:
	Core.useBrowserLayoutEffect(() => {
		Object.assign(Context.currentValue, utilities);

		return () => {
			// Reset the globally accessible utilities object when the component unmounts:
			Object.assign(Context.currentValue, Context.defaultValue);
		};
	}, [utilities]);

	const enabled = Boolean(withAdnuntiusDisplay || withDisplay);

	return (
		<Context.Provider value={utilities}>
			<Display.Provider enabled={enabled}>{children}</Display.Provider>
		</Context.Provider>
	);
}

export namespace Provider {
	export const displayName = namespace.Utilities.nameof({ Provider });

	/** Configuration for setting up the advertising utilities. */
	export type Props = {
		config: Context & { supportedAdTypeOptions: Services.Providers.Options };
		children: React.ReactNode;
	};
}
