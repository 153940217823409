import React from 'react';
import { delay, noop, PromiseWithResolvers } from '@smd/utilities';
import * as Trigger from './Trigger';
import { useIsMounted } from './useIsMounted';

export type Callback = {
	(): Callback.Result | undefined;
};

export namespace Callback {
	export type Result = {
		effect?: Callback.Effect;
		cleanup?: Callback.Cleanup;
	};

	export type Effect = (provided: {
		abortSignal: AbortSignal;
		unblockCleanup(): void;
	}) => Promise<void>;

	export type Cleanup = (provided: {
		abortSignal: AbortSignal;
		unblockEffect(): void;
	}) => Promise<void>;
}

export function use(callback: Callback, dependencies?: ReadonlyArray<unknown>) {
	const cleanupCompletedRef = React.useRef(true);
	const [effectTrigger, triggerEffect] = Trigger.use(true, effectTrigger => !effectTrigger);
	const isMounted = useIsMounted();
	const [, setError] = React.useState<unknown>();

	/**
	 * Used when outside the scope of React, e.g. async errors. Throws an error
	 * that can be caught by a React error boundary.
	 */
	const throwError = React.useCallback(
		(error: unknown) => {
			setError(() => {
				throw error;
			});
		},
		[setError],
	);

	React.useEffect(
		() => {
			// When in the process of cleaning up a previous config, let it finish
			// before attempting to set up a new config:
			if (!cleanupCompletedRef.current) return;

			const { effect, cleanup } = callback() ?? {};

			// If neither effect nor cleanup are provided, there's nothing to do:
			if (!(effect || cleanup)) return;

			const effectAbortController = new AbortController();

			const { promise: cleanupUnblockedPromise, resolve: unblockCleanup } =
				PromiseWithResolvers.of();

			const effectCompletedPromise =
				effect &&
				(async function effectAsync() {
					try {
						await effect({
							abortSignal: effectAbortController.signal,
							unblockCleanup,
						});
					} catch (error) {
						throwError(error);
						return;
					}
				})();

			// No-op just to mark the promise as handled, since it might reject before cleanup is invoked:
			effectCompletedPromise?.catch(noop);

			return () =>
				void (async function cleanupAsync() {
					// Set a flag to hold off a new effect until after cleanup is completed:
					cleanupCompletedRef.current = false;

					if (effectCompletedPromise) {
						try {
							// Attempt to abort effect if it's still running:
							effectAbortController.abort();

							// Wait for the effect to complete before cleanup, or for the unblocking signal to fire:
							await Promise.race([effectCompletedPromise, cleanupUnblockedPromise]);
						} catch (error) {
							throwError(error);
							return;
						}
					}

					if (cleanup) {
						const { promise: effectUnblockedPromise, resolve: unblockEffect } =
							PromiseWithResolvers.of();

						const cleanupAbortController = new AbortController();

						try {
							if (!isMounted()) cleanupAbortController.abort();

							const cleanupCompletedPromise = cleanup({
								abortSignal: cleanupAbortController.signal,
								unblockEffect,
							});

							// Wait for the cleanup to complete before resetting the flag, or for the unblocking
							// signal to fire:
							await Promise.race([cleanupCompletedPromise, effectUnblockedPromise]);
						} catch (error) {
							throwError(error);
							return;
						}
					}

					// In case both the provided effect and cleanup functions are synchronous, the triggerEffect
					// function will cause a double effect run. To mitigate this, we force asynchronous behavior
					// by waiting for the next tick before calling triggerEffect:
					await delay(0);

					if (!isMounted()) return;

					cleanupCompletedRef.current = true;
					triggerEffect();
				})();
		},
		dependencies?.length === 0 ? dependencies : [effectTrigger, ...(dependencies ?? [])],
	);
}
