// TODO: autogenerate this in Sho.Ad:
export enum AdPosition {
	Article1 = 'article-1',
	Article2 = 'article-2',
	Audience = 'audience',
	Bottom = 'bottom',
	GallerySkyscraperLeft = 'gallery-skyscraper-left',
	GallerySkyscraperRight = 'gallery-skyscraper-right',
	RelatedBottom = 'intermingle-1',
	SkyscraperLeftBottom = 'skyscraper-left-2',
	SkyscraperLeftTop = 'skyscraper-left',
	SkyscraperRightBottom = 'skyscraper-right-2',
	SkyscraperRightTop = 'skyscraper-right',
	Top = 'top',
	Topscroll = 'topscroll',
	Wallpaper = 'wallpaper',
}
