import { as, isNotNullOrUndefined } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';

export namespace AdType {
	const _Supported = [
		ShoAd.AdType.AdSenseForSearch,
		ShoAd.AdType.AdnuntiusDisplay,
		ShoAd.AdType.Display,
	] as const satisfies ReadonlyArray<ShoAd.AdType>;

	export const Supported = Object.assign(_Supported, {
		Keys: Object.freeze(
			_Supported
				.map(supportedAdType =>
					Object.entries(ShoAd.AdType).find(([, adType]) => adType === supportedAdType),
				)
				.filter(isNotNullOrUndefined)
				.filter((entry): entry is [AdType.Supported.Keys, AdType.Supported] =>
					_Supported.includes(as(entry[1])),
				)
				.map(([key]) => key),
		),
	} as const);

	export type Supported = (typeof _Supported)[number];

	export namespace Supported {
		export type Keys = keyof {
			[TAdTypeKey in Keys.Any as Keys.ExtractSupported<TAdTypeKey>]: never;
		};

		export namespace Keys {
			export type Any = keyof typeof ShoAd.AdType;

			export type ExtractSupported<TAdTypeKey extends Any> =
				(typeof ShoAd.AdType)[TAdTypeKey] extends Supported ? TAdTypeKey : never;
		}
	}
}
