import React from 'react';
import type { Nullable } from '@smd/utilities';
import * as Core from '../core';
import { Manager } from './Manager';
import { Context } from './Context';
import * as namespace from './.namespace';

/** A component that provides the tracking manager. */
export function Provider({ children, config: { trackingManager, endpoint } }: Provider.Props) {
	const defaultTrackingManager = Core.useConstRef(() =>
		Manager.from({ shoAdConfig: [{ endpoint: `${endpoint}track` }] }),
	);

	return (
		<Context.Provider value={trackingManager ?? defaultTrackingManager}>
			{children}
		</Context.Provider>
	);
}

export namespace Provider {
	export const displayName = namespace.Tracking.nameof({ Provider });

	export type Props = {
		config: Props.Config;
		children: React.ReactNode;
	};

	export namespace Props {
		export type Config = {
			trackingManager: Nullable<Manager>;
			endpoint: string;
		};
	}
}
