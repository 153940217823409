import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import { IncomingHttpHeaders } from 'http';
import {
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpModelsSearchUriRequest,
  SmdBasSrpModelsSuggestion,
  SmdBasSrpWebApiModelsWebSearchResponse,
  SmdBasSrpModelsSavedSearchModel,
  EcgdkBilbasenListingContractDTOsEnumsSearchCategoryType,
  SmdBasSrpWebApiModelsSeoData,
  SmdBasSrpWebApiModelsRelatedListingWebModel,
} from 'types/api';
import { del, get, post } from './common';

const isServerSide = typeof window === 'undefined';

export const baseURL = new URL(
  isServerSide
    ? process.env.API_ENDPOINT + '/api/search/'
    : window.location.origin + '/api/search/'
); // Server-side we connect directly to the API - client side we use rewrites (see next.config.js)

export const getSearchResultsByUrl = async (
  uri: string,
  signal?: RequestInit['signal'],
  headers?: IncomingHttpHeaders
) => {
  const body: SmdBasSrpModelsSearchUriRequest = { searchUri: uri }; // Body is extracted into const to ensure typescript errors if interface changes
  return post<
    SmdBasSrpWebApiModelsWebSearchResponse,
    SmdBasSrpModelsSearchUriRequest
  >(new URL('by-url', baseURL), body, signal, headers);
};

export const getSearchResultsBySearchRequest = (
  parameters: SmdBasSrpModelsSearchRequest,
  signal: RequestInit['signal']
) =>
  post<SmdBasSrpWebApiModelsWebSearchResponse>(
    new URL('by-request', baseURL),
    parameters,
    signal
  );

export const getMetaTexts = async (): Promise<SmdBasSrpModelsSearchInfo> => {
  const req = await fetch(new URL('searchInfo', baseURL));

  if (!req.ok) {
    throw new Error('Request to fetch meta texts failed');
  }

  const data = await req.json();
  return data;
};

export type SaveSearchResponse = {
  emailNotification: boolean;
  pushNotification: boolean;
  name: string;
  emailNotificationLabel?: string;
  pushNotificationLabel?: string;
  isExistingSaveSearch: boolean;
};

export const fetchSaveSearchDetails = async (
  searchRequest: SmdBasSrpModelsSearchRequest
) =>
  post<SaveSearchResponse>(
    new URL('savedsearches/name', baseURL),
    searchRequest
  );

export const postSaveSearchDetails = async (
  body: SmdBasSrpModelsSavedSearchModel
) =>
  post<SaveSearchResponse>(new URL('savedsearches/save', baseURL), body, null);

export const getSearchSuggestions = async (
  searchText: string,
  signal: RequestInit['signal'] = undefined
) => {
  const uri = new URL('suggestions', baseURL);
  uri.searchParams.set('freeText', searchText);
  uri.searchParams.set('count', '5');

  return get<SmdBasSrpModelsSuggestion[]>(uri, signal);
};

export type UserDetailsDto = {
  userProfile?: {
    isAuthenticated?: boolean;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    homePhone?: string;
  };
  favorites?: {
    count: number;
  };

  /** The user-specific parts of the meridian dataLayer. */
  dataLayer?: MeridianDataLayerValue;
};

export const getUserDetails = async (headers?: IncomingHttpHeaders) => {
  return get<UserDetailsDto>(new URL('userdata/props', baseURL), null, headers);
};

// Favorites endpoints are only being called clientside, so we do not need to pass headers
export const saveFavorite = async (externalId?: number) =>
  post(new URL(`favorites/${externalId}`, baseURL), { externalId });

export const deleteFavorite = async (externalId?: number) =>
  del(new URL(`favorites/${externalId}`, baseURL));

export const getSeoContentByParams = async (
  make: string,
  model: string,
  modelSeries: string = '',
  text: string = '',
  category: EcgdkBilbasenListingContractDTOsEnumsSearchCategoryType = 'Unknown',
  region: string = '',
  city: string = ''
) => {
  const uri = new URL('seo', baseURL);
  uri.searchParams.set('make', make);
  uri.searchParams.set('model', model);
  uri.searchParams.set('modelSeries', modelSeries);
  uri.searchParams.set('text', text);
  uri.searchParams.set('category', category);
  uri.searchParams.set('region', region);
  uri.searchParams.set('city', city);

  return get<SmdBasSrpWebApiModelsSeoData>(uri);
};

export const getSeoContentByUrl = async (url: string) => {
  const uri = new URL('seoFromUrl', baseURL);
  uri.searchParams.set('url', url);
  return get<SmdBasSrpWebApiModelsSeoData>(uri);
};

export const getRelatedListings = (parameters: SmdBasSrpModelsSearchRequest) =>
  post<SmdBasSrpWebApiModelsRelatedListingWebModel[]>(
    new URL('related', baseURL),
    parameters
  );
