import React from 'react';
import { as } from '@smd/utilities';
import { useIsMounted } from './useIsMounted';

export function use(onUnmounted?: use.OnUnmounted): use.Result<number>;

export function use<T>(
	initialValue: T,
	nextValueFactory: use.NextValueFactory<T>,
	onUnmounted?: use.OnUnmounted,
): use.Result<T>;

export function use<T = number>(
	maybeInitialValueOrOnUnmounted?: use.OnUnmounted | T,
	maybeNextValueFactory?: use.NextValueFactory<T>,
	maybeOnUnmounted?: use.OnUnmounted,
): use.Result<T> {
	const isMounted = useIsMounted();

	const [currentValue, setCurrentValue] = React.useState<T>(
		as(maybeNextValueFactory ? maybeInitialValueOrOnUnmounted : 0),
	);

	const trigger = React.useCallback(() => {
		if (!isMounted()) {
			const onUnmounted: use.OnUnmounted | undefined = as(
				maybeNextValueFactory ? maybeOnUnmounted : maybeInitialValueOrOnUnmounted,
			);

			onUnmounted?.();
			return;
		}

		setCurrentValue(as(maybeNextValueFactory ?? use.NextValueFactory.defaultValue));
	}, [setCurrentValue]);

	return [currentValue, trigger] as const;
}

export namespace use {
	export type NextValueFactory<T> = (previousValue: T) => T;

	export namespace NextValueFactory {
		export const defaultValue: NextValueFactory<number> = previousValue => ++previousValue;
	}

	export type Result<T> = readonly [currentValue: T, trigger: VoidFunction];

	export type OnUnmounted = VoidFunction;
}
