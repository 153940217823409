import * as Core from '../../../core';
import * as namespace from './.namespace';
import * as Api from './Api';

export namespace IsSticky {
	/** Returns whether the given position is sticky. */
	export function use(positionName: string) {
		return Context.use().stickyPositions[positionName] ?? null;
	}
}

export namespace Wallpaper {
	/** Returns the wallpaper context from display ads utilities. */
	export function use() {
		return Context.use().wallpaper;
	}
}

export type Context = Api.State;

export const Context = Object.assign(
	Core.ExtendedContext.of(Api.State.defaultValue, namespace.Display),
	{ IsSticky, Wallpaper } as const,
);
