import type React from 'react';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Tracking from '@smd/tracking';
import type * as Core from '../../core';
import { Context } from '../Context';

export function use(
	elementRef: React.RefObject<HTMLElement>,
	adType: Core.AdType.Supported,
	container: string,
	properties?: ShoAd.AdSlotProperties,
) {
	const trackingManager = Context.use();

	const handleInView = () => {
		trackingManager?.handleEvent(
			{
				eventType: ShoAd.AdCountEvent.ViewableImpression,
				adType,
				container,
			},
			properties,
		);
	};

	Tracking.useInViewCallback(elementRef, handleInView, {
		// the following values should match Google's definition of a viewable impression:
		triggerOnce: true,
		threshold: 0.5,
		delay: 1000,
	});
}
