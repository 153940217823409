import type React from 'react';
import { AnyNonNullable, nameof } from '@smd/utilities';
import type { Service } from '.';

export type Provider<T extends Provider.Props = Provider.Props> = React.ComponentType<T> &
	Required<Pick<React.ComponentType<T>, 'displayName'>>;

export namespace Provider {
	export type Props<T extends object = AnyNonNullable> = T & {
		children: React.ReactNode;
		enabled: boolean;
	};

	export function from<TStatic extends Service.Static>(
		Service: TStatic,
		namespace: from.Namespace = from.Namespace,
	) {
		const Provider: from.Static<TStatic> = ({ enabled, children, ...settings }) =>
			Service.use(settings).useRender(enabled, children);

		Provider.displayName = namespace.Service.nameof({ Provider });

		return Provider;
	}

	export namespace from {
		export const Namespace = { Service: { nameof: nameof } };

		export type Namespace = { Service: nameof.Namespace };

		export type Static<TStatic extends Service.Static> = Provider<
			Provider.Props<TStatic extends Service.Static<infer TSettings> ? TSettings : never>
		>;
	}
}
