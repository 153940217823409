import '../styles/global.scss';
import type { AppProps } from 'next/app';
import React from 'react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Layout from '@/organisms/Layout';
import { AdProvider } from '../components/advertising/AdProvider';
import Script from 'next/script';
import { HelmetProvider } from 'react-helmet-async';
import { canUseDOM } from '@smd/utilities';
import { CookieProvider } from '@/hooks/useCookie';
import type SearchResults from './brugt/[...path]';
import type Frontpage from '.';
import { SourcepointProvider } from '@smd/cmp-react';
import { PulseTracking } from 'components/PulseTracking';

export type PageProps = SearchResults.Props | Frontpage.Props;

type Props = AppProps<PageProps>;

if (!canUseDOM()) {
  HelmetProvider.canUseDOM = false;
}

export default function App({ Component, pageProps }: Props) {
  const [queryClient] = React.useState(() => new QueryClient());

  // TODO: Consider if this should just be part of the favorites hook/mutation
  const handleUpdate = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['user'] });
  }, [queryClient]);

  React.useEffect(() => {
    if (!canUseDOM()) return;
    window.addEventListener('updateUser', handleUpdate);

    return () => {
      window.removeEventListener('updateUser', handleUpdate);
    };
  });

  const columnsCount = pageProps.dataLayer?.p?.pl?.includes('web') ? 1 : 3;

  const shouldEnableAdsManually =
    pageProps.dataLayer?.p?.t?.toLowerCase() !== 'homepage';

  const staticPageView =
    pageProps.dataLayer?.p?.t?.toLowerCase() === 'homepage';

  const cookies = 'cookies' in pageProps ? pageProps.cookies : {};

  return (
    <HelmetProvider context={pageProps.helmetContext}>
      <PulseTracking {...{ staticPageView }} />

      <SourcepointProvider.Default.Bilbasen>
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`window.dataLayer = window.dataLayer || [];` +
            `window.dataLayer.push(${JSON.stringify(
              pageProps.dataLayer ?? {}
            )});` +
            `window.dataLayer.push(${JSON.stringify({
              pulse: pageProps.pulse ?? {},
            })});` +
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
            `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
            `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
            `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
            `})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`}
        </Script>
        <CookieProvider cookies={cookies}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <AdProvider
                columnsCount={columnsCount}
                enableManually={shouldEnableAdsManually}
                dehydratedConfig={pageProps.ads}
              >
                <Layout>
                  <Component {...pageProps} />
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} />
              </AdProvider>
            </Hydrate>
          </QueryClientProvider>
        </CookieProvider>
      </SourcepointProvider.Default.Bilbasen>
    </HelmetProvider>
  );
}
