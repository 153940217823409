import React from 'react';
import Cookies from 'js-cookie';

type CookieContextType = Record<string, string> | null;

const CookieContext = React.createContext<CookieContextType>(null);

type ProviderProps = {
  children: React.ReactNode;
  cookies: CookieContextType;
};

export const CookieProvider = ({ children, cookies }: ProviderProps) => {
  return (
    <CookieContext.Provider value={cookies}>{children}</CookieContext.Provider>
  );
};

export const useCookie = (
  cookieName: string,
  defaultValue?: string
): [
  string | undefined,
  (newValue: string, options?: Cookies.CookieAttributes) => void,
  () => void
] => {
  const context = React.useContext(CookieContext);
  const [value, setValue] = React.useState<string | undefined>(
    () => Cookies.get(cookieName) || context?.[cookieName] || defaultValue
  );

  if (context === null) {
    throw new Error('useCookie must be used within a CookieProvider');
  }

  const updateCookie = React.useCallback(
    (newValue: string, options?: Cookies.CookieAttributes) => {
      Cookies.set(cookieName, newValue, options);
      setValue(newValue);
    },
    [cookieName]
  );

  const deleteCookie = React.useCallback(() => {
    Cookies.remove(cookieName);
    setValue(defaultValue);
  }, [defaultValue, cookieName]);

  return [value, updateCookie, deleteCookie];
};
