import { canUseDOM, Lazy, type Nullable } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import type * as Tracking from '../../../tracking';
import { TypeGuard } from '../../TypeGuard';
import type { Api } from '../Api';

export function* from(
	trackingManager: Nullable<Tracking.Manager>,
	{ kv: pageLevelKeyValues }: ShoAd.AdnuntiusDisplayPageProperties,
	...adSlots: ReadonlyArray<ShoAd.IAdSlot>
) {
	const adType = ShoAd.AdType.AdnuntiusDisplay;

	for (const adSlot of adSlots) {
		if (!TypeGuard.Slot.is(adSlot)) continue;

		const { kv: unitLevelKeyValues, ...adUnit } = adSlot.item.adnuntiusDisplay;

		const createEventHandler = (eventType: ShoAd.AdCountEvent) =>
			function handleEvent<T>(data: T) {
				trackingManager?.handleEvent(
					{ adType, eventType, container: adUnit.targetId },
					adSlot.item,
					data,
				);
			};

		yield {
			...adUnit,
			kv: from.keyValues(pageLevelKeyValues, unitLevelKeyValues),
			requestParams: adUnit.requestParams ?? from.defaultRequestParams,
			onImpressionResponse: createEventHandler(ShoAd.AdCountEvent.Impression),
			onNoMatchedAds: createEventHandler(ShoAd.AdCountEvent.UnfilledImpression),
			onVisible: createEventHandler(ShoAd.AdCountEvent.Rendered),
			onViewable: createEventHandler(ShoAd.AdCountEvent.ViewableImpression),
		} as const satisfies Api.Config.AdUnit;
	}
}

export namespace from {
	export function keyValues(
		pageLevelKeyValues: ShoAd.AdnuntiusDisplayPageProperties['kv'],
		slotLevelKeyValues: Api.Config.AdUnit['kv'] = [],
	) {
		return [...pageLevelKeyValues, ...slotLevelKeyValues];
	}

	const proximity = Lazy.of(domCanBeUsed => {
		// Determine if the scrolling experience is mobile-like:
		const screenWidth = domCanBeUsed
			? Math.max(1, (window.screen.width as number | undefined) ?? 0)
			: 1;

		const screenHeight = domCanBeUsed
			? Math.max(1, (window.screen.height as number | undefined) ?? 0)
			: 1;

		const deviceAspectRatio = screenWidth / screenHeight;
		const { innerWidth = 800, innerHeight = 800 } = domCanBeUsed ? window : {};
		const isMobileLike = innerWidth < 600 && deviceAspectRatio < 1;

		// Fetch when ads enter the current viewport plus two viewports:
		const fetchMarginInViewports = 2;

		// Fetch twice as eagerly on mobile, due to faster user scrolling trends:
		const deviceScaling = isMobileLike ? 2 : 1;

		// Return the fetch margin, beyond the current viewport, in pixels:
		return deviceScaling * fetchMarginInViewports * innerHeight;
	}, canUseDOM());

	export const defaultRequestParams = {
		load: 'lazy',
		get proximity() {
			return proximity();
		},
	} as const satisfies Api.Config.AdUnit.RequestParams;
}
