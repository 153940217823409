import React from 'react';
import * as Core from '../core';
import * as namespace from './.namespace';
import { Slot } from './Slot';
import { TypeGuard } from './TypeGuard';

function Builder() {
	return Core.AdPositionBuilder.from(TypeGuard.Slot.select)
		.setElementType<HTMLDivElement>()
		.mapProps(Core.injectPositionClassNames);
}

export const Position = Object.assign(
	Builder()
		.withComponent(function Position({ adSlot, props }) {
			return <Slot {...props} properties={adSlot.item} />;
		})
		.build(),
	{ Builder },
);

Position.displayName = namespace.Adnuntius.nameof({ Position });

export type Position = typeof Position;
