import { type Nullable, PromiseWithResolvers, noop, isNotNullOrUndefined } from '@smd/utilities';
import * as Core from '../../../../core';
import type { Api } from '../../Api';
import * as namespace from './.namespace';

export class EventForwarder {
	readonly #abortSignals;
	readonly #destroyAbortEventListeners;
	readonly #stack = new Array<EventForwarder.Callback>();
	readonly #iterator = this.#bucket();
	#deferred: EventForwarder.Deferred | null = null;
	#destroyed = false;

	constructor(...abortSignals: ReadonlyArray<Nullable<AbortSignal>>) {
		this.#abortSignals = abortSignals.filter(isNotNullOrUndefined);
		const onAbort = this.#onAbort.bind(this);

		for (const abortSignal of this.#abortSignals) {
			abortSignal.addEventListener('abort', onAbort);
		}

		this.#destroyAbortEventListeners = () => {
			for (const abortSignal of this.#abortSignals) {
				abortSignal.removeEventListener('abort', onAbort);
			}
		};
	}

	destroy() {
		if (this.#destroyed) return;

		this.#destroyed = true;
		this.#iterator.return().catch(noop);

		if (this.#deferred) {
			this.#deferred.reject(new EventForwarder.DestroyedError());
			this.#deferred = null;
		}

		this.#destroyAbortEventListeners();
	}

	collect<
		TCallbackName extends EventForwarder.Callback.Name,
		TParameters extends EventForwarder.Callback.Parameters<TCallbackName>,
	>(callbackName: TCallbackName, executor: EventForwarder.Executor<TCallbackName, TParameters>) {
		if (this.#destroyed) return;

		const value = [
			callbackName,
			executor(callback => {
				try {
					callback('PREBID', callbackName);
				} catch (error) {
					Core.log.error('PREBID', 'runAuction', callbackName, 'Logging error occurred', {
						error,
					});
				}
			}),
		] as const as EventForwarder.Callback;

		if (this.#deferred) {
			this.#deferred.resolve(value);
			this.#deferred = null;

			return;
		}

		this.#stack.push(value);
	}

	async *[Symbol.asyncIterator]() {
		yield* this.#iterator;
	}

	async *#bucket(): AsyncGenerator<EventForwarder.Callback, void, void> {
		try {
			while (true) {
				for (const abortSignal of this.#abortSignals) abortSignal.throwIfAborted();

				const deferred = PromiseWithResolvers.of<EventForwarder.Callback>();
				const callback = this.#stack.shift();

				if (callback) deferred.resolve(callback);
				else this.#deferred = deferred;

				const result = await deferred.promise;
				yield result;

				// Break iteration when the auction is done:
				const [type] = result;
				if (type === 'onAuctionDone') break;
			}
		} finally {
			this.destroy();
		}
	}

	async #throw(reason?: unknown) {
		try {
			return await this.#iterator.throw(reason);
		} finally {
			if (this.#deferred) {
				this.#deferred.reject(reason);
				this.#deferred = null;
			}

			this.destroy();
		}
	}

	#onAbort(event?: Event) {
		const reason: unknown =
			event?.target && event.target instanceof AbortSignal && 'reason' in event.target
				? event.target.reason
				: undefined;

		this.#throw(reason).catch(noop);
	}
}

export namespace EventForwarder {
	export type Callback<TCallbackName extends Callback.Name = Callback.Name> = {
		[T in TCallbackName]: readonly [callbackName: T, parameters: Callback.Parameters<T>];
	}[TCallbackName];

	export namespace Callback {
		export type Name =
			| 'onBeforeAuctionSetup'
			| 'onAuctionDone'
			| 'onAuctionInitDone'
			| 'onBeforeAdRequest'
			| 'onSlotAndUnit'
			| 'refresh';

		export type Parameters<TCallbackName extends Callback.Name = Callback.Name> =
			globalThis.Parameters<NonNullable<Callback.Map[TCallbackName]>>[0];

		export type Map = Pick<Api.LoadPrebid.Parameters & Api.LoadPrebid.GoogletagCalls, Name>;
	}

	export type Executor<
		TCallbackName extends Callback.Name,
		TParameters extends Callback.Parameters<TCallbackName>,
	> = {
		(withSafeLogging: Executor.WithSafeLogging<TCallbackName>): TParameters;
	};

	export namespace Executor {
		export type WithSafeLogging<TCallbackName extends EventForwarder.Callback.Name> = {
			(callback: Executor.Callback<TCallbackName>): void;
		};

		export type Callback<TCallbackName extends EventForwarder.Callback.Name> = {
			(type: 'PREBID', callbackName: TCallbackName): void;
		};
	}

	export type Deferred = ReturnType<typeof PromiseWithResolvers.of<Callback>>;

	export class DestroyedError extends Error {
		static {
			this.prototype.name = namespace.Auction.nameof({ EventForwarder }, { DestroyedError });
		}

		constructor() {
			super('The instance is already destroyed!');
		}
	}
}
