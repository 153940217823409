import * as Core from '../../core';
import * as Styles from '../Styles';
import type { Config } from './Config';

const buildProperties = Core.buildCssVariableProperties(Styles.layoutClass);

/**
 * Builds the CSS properties for the ads layout variables.
 */
const cssProperties = (...args: Parameters<typeof buildProperties>) =>
	Core.propertiesToCss(buildProperties(...args));

const ON = 'initial' as const;
const OFF = ' ' as const;

/**
 * Builds the CSS for the ads layout variables.
 */
export const from = ({
	maxContentWidth,
	breakpoints: { mediumScreen, largeScreen },
}: Config) => Core.css`
	:root {
		${cssProperties({ maxContentWidth })}
	}

	@media screen and (max-width: calc(${mediumScreen} - 1px)) {
		:root {
			${cssProperties({
				isSmallScreen: ON,
				isNotSmallScreen: OFF,
				isMediumScreen: OFF,
				isNotMediumScreen: ON,
				isLargeScreen: OFF,
				isNotLargeScreen: ON,
			})}
		}
	}

	@media screen and (min-width: ${mediumScreen}) and (max-width: calc(${largeScreen} - 1px)) {
		:root {
			${cssProperties({
				isSmallScreen: OFF,
				isNotSmallScreen: ON,
				isMediumScreen: ON,
				isNotMediumScreen: OFF,
				isLargeScreen: OFF,
				isNotLargeScreen: ON,
			})}
		}
	}

	@media screen and (min-width: ${largeScreen}) {
		:root {
			${cssProperties({
				isSmallScreen: OFF,
				isNotSmallScreen: ON,
				isMediumScreen: OFF,
				isNotMediumScreen: ON,
				isLargeScreen: ON,
				isNotLargeScreen: OFF,
			})}
		}
	}
`;
