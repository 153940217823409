import React from 'react';
import * as Core from '../../../../../core';
import type { Hook } from './Hook';

/**
 * A hook that creates the function that sets positions to sticky.
 */
export const use: Hook<Setter> = setUtilities =>
	React.useCallback(
		(positions, value = true) => {
			Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Api', 'Sticky', { positions, value });

			setUtilities(prevState => ({
				...prevState,
				stickyPositions: positions.reduce(
					(stickyPositions, positionName) => ({
						...stickyPositions,
						[positionName]: value,
					}),
					{},
				),
			}));
		},
		[setUtilities],
	);

export type Setter = {
	(positions: Array<string>, value?: boolean): void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
type _Setter = Setter;

export namespace TopSkyscrapers {
	/**
	 * A hook that creates the function that sets the top skyscrapers to sticky.
	 */
	export const use: Hook<Setter, [_Setter]> = setPositionsSticky =>
		React.useCallback(
			(stickySetting = 'BOTH') => {
				Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Api', 'Sticky.TopSkyscrapers', {
					stickySetting,
				});

				setPositionsSticky(
					[Core.AdPosition.SkyscraperLeftTop, Core.AdPosition.SkyscraperRightTop],
					stickySetting.toUpperCase() !== 'NONE',
				);
			},
			[setPositionsSticky],
		);

	export type Setter = {
		(stickySetting: Setter.StickySetting): void;
	};

	export namespace Setter {
		export type StickySetting = 'NONE' | 'BOTH';
	}
}
