/* eslint-disable react/display-name */
import styles from './Button.module.scss';
import cx from 'classnames';
import React from 'react';

type Props = {
  fullWidth?: boolean;
  variant?: 'Primary' | 'Secondary' | 'Tertiary' | 'Label' | 'DarkLabel' | 'SecondaryDark';
  disabled?: boolean;
  startElement?: React.ReactNode;
  endElement?: React.ReactNode;
};

// Types below are for polymorphic components - see global.d.ts for the helper types
type ButtonProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, Props>;

type ButtonComponent = <C extends React.ElementType = 'button'>(
  props: ButtonProps<C>
) => React.ReactElement | null;

export const Button: ButtonComponent = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      fullWidth,
      variant,
      disabled,
      startElement,
      endElement,
      as,
      children,
      ...other
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const classes = cx(
      styles.button,
      {
        [styles.fullWidth]: fullWidth,
        [styles.secondary]: variant === 'Secondary',
        [styles.secondaryDark]: variant === 'SecondaryDark',
        [styles.tertiary]: variant === 'Tertiary',
        [styles.label]: variant === 'Label',
        [styles.darkLabel]: variant === 'DarkLabel',
        [styles.disabled]: disabled,
      },
      other.className
    );
    const Component = as || 'button';

    return (
      <Component {...other} className={classes} ref={ref}>
        {startElement}
        <span>{children}</span>
        {endElement}
      </Component>
    );
  }
);

export default Button;
