import * as Core from '../../../../core';
import * as Hooks from './Hooks';
import type { State } from './State';
import type { Config } from '../../../../ad-manager/Service/Config';

export namespace Window {
	/**
	 * Exposes display ads utilities on {@link window}.
	 */
	export function use(setState: State.Setter) {
		const setMediaWallpaper = Hooks.Wallpaper.Media.use(setState);
		const setPositionEmpty = Hooks.Empty.use();
		const setPositionsSticky = Hooks.Sticky.use(setState);
		const setTopSkyscrapersSticky = Hooks.Sticky.TopSkyscrapers.use(setPositionsSticky);
		const setWallpaper = Hooks.Wallpaper.use(setState);

		Core.useBrowserLayoutEffect(() => {
			const next = {
				setMediaWallpaper,
				setPositionEmpty,
				setPositionsSticky,
				setTopSkyscrapersSticky,
				setWallpaper,
			} as const satisfies Window.DisplayAdsUtilities;

			if (window.DisplayAdsUtilities) {
				Object.assign(window.DisplayAdsUtilities, next);
			} else {
				window.DisplayAdsUtilities = next;
			}

			Core.log(
				'CONFIGURATION',
				'Ads.Utilities.Display',
				'Global',
				'window.DisplayAdsUtilities',
				window.DisplayAdsUtilities,
			);
		}, [
			setMediaWallpaper,
			setPositionEmpty,
			setPositionsSticky,
			setTopSkyscrapersSticky,
			setWallpaper,
		]);
	}
}

export type Window = {
	DisplayAdsUtilities?: Window.DisplayAdsUtilities;
};

export namespace Window {
	export type DisplayAdsUtilities = {
		setMediaWallpaper: Hooks.Wallpaper.Media.Setter;
		setPositionEmpty: Hooks.Empty.Setter;
		setPositionsSticky: Hooks.Sticky.Setter;
		setTopSkyscrapersSticky: Hooks.Sticky.TopSkyscrapers.Setter;
		setWallpaper: Hooks.Wallpaper.Setter;
		customTargeting?: Config.Common.Targeting;
	};
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type _Window = Window;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends _Window {}
}
