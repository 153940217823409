import { getMeridianDataLayerOrNull, MeridianDataLayerValue } from '@smd/datalayer-typings';
import { log } from '../utils';
import * as Validators from './Validators';

/**
 * Attempts to grab a fallback value for when `dataLayer` is not available on
 * {@link window}, e.g. due to ad blockers or other issues. It should only be
 * used to improve resilience, as it carries with it a risk of masking any
 * potential issues related to the `dataLayer`.
 */
export function get() {
	try {
		// Try one last time, to get basic dataLayer values:
		const dataLayer = getMeridianDataLayerOrNull();

		if (dataLayer && Validators.Basic.validate(dataLayer)) {
			log('DATALAYER', 'Resolve', 'ForAds', 'Grabbed basic dataLayer, likely incomplete', {
				dataLayer,
			});

			return dataLayer;
		}
	} catch (error) {
		log.error('DATALAYER', 'Resolve', 'ForAds', 'Attempted grabbing basic dataLayer, but failed', {
			error,
		});
	}

	const contextSpecificGetters = [get.donut, get.bootstrapper, get.nextJs] as const;

	for (const getInContext of contextSpecificGetters) {
		try {
			const fallbackDataLayer = getInContext();

			if (!fallbackDataLayer) continue;
			if (!Validators.Basic.validate(fallbackDataLayer)) continue;

			log.warn('DATALAYER', 'Resolve', 'ForAds', 'Grabbed fallback dataLayer, likely incomplete', {
				fallbackDataLayer,
			});

			return fallbackDataLayer;
		} catch {
			// Ignore
		}
	}

	log.error(
		'DATALAYER',
		'Resolve',
		'ForAds',
		'Attempted grabbing SSR fallback dataLayer, but none was found',
	);

	return null;
}

export namespace get {
	export function bootstrapper() {
		const bootstrapperWindow: bootstrapper.Window = window;
		return bootstrapperWindow[bootstrapper.globalKey]?.tracking?.gtm?.dataLayer || null;
	}

	export namespace bootstrapper {
		export const globalKey = '_props';

		export type Window = globalThis.Window & {
			[bootstrapper.globalKey]?: { tracking?: { gtm?: { dataLayer?: MeridianDataLayerValue } } };
		};
	}
}

export namespace get {
	export function donut() {
		const donutWindow: donut.Window = window;
		return donutWindow[donut.globalKey]?.dataLayer || null;
	}

	export namespace donut {
		export const globalKey = '_props';

		export type Window = globalThis.Window & {
			[donut.globalKey]?: { dataLayer?: MeridianDataLayerValue };
		};
	}
}

export namespace get {
	export function nextJs() {
		const nextDataScriptElement = document.getElementById('__NEXT_DATA__');
		const rawNextData = nextDataScriptElement?.textContent || 'null';
		const nextData = JSON.parse(rawNextData) as nextJs.NextData | null;
		return nextData?.props?.pageProps?.dataLayer || null;
	}

	export namespace nextJs {
		export type NextData = {
			props?: { pageProps?: { dataLayer?: MeridianDataLayerValue } };
		};
	}
}
