import type { Nullable } from '@smd/utilities';
import type * as ShoAd from '@smd/sho-advertising-typings';
import { hasGoogleConsent } from '@smd/cmp-sourcepoint';
import * as Core from '../../core';
import * as namespace from './.namespace';

class ApiLoader extends Core.ApiLoader<Api> {
	override readonly globalKey = '_googCsa';
	protected override readonly namespace = namespace;
	protected override readonly scriptUrl = 'https://www.google.com/adsense/search/ads.js';

	override async execute<R>(
		executor: Core.ApiLoader.Executor<Api, R>,
		...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
	) {
		for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();
		const globalRef = await this.load();

		for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();
		return executor.apply(globalRef, [...abortSignals]);
	}

	protected override resolveReference() {
		const globalRef = window[this.globalKey];
		if (!globalRef) throw new Core.ApiLoader.UnavailableError(this.globalKey);
		return globalRef;
	}

	protected override async shouldLoad() {
		return await hasGoogleConsent();
	}

	protected override async prepare() {
		// This is taken from https://developers.google.com/custom-search-ads/docs/implementation-guide
		const globalRef: Api = (window[this.globalKey] ??= function () {
			// eslint-disable-next-line prefer-rest-params
			((globalRef.q as typeof globalRef.q | undefined) ??= new Array()).push(arguments);
		});

		globalRef.t = +new Date();

		return Promise.resolve();
	}
}

export const Api = new ApiLoader();

export type Api = {
	(...params: Api.Parameters): void;
	q?: Array<unknown>;
	t?: number;
};

export namespace Api {
	export type Parameters = readonly [
		format: Parameters.Format,
		pageLevelParameters: Parameters.PageLevelParameters,
		...blocks: [Parameters.Block, ...ReadonlyArray<Parameters.Block>],
	];

	export namespace Parameters {
		export type Format = 'ads';

		export type PageLevelParameters = Readonly<ShoAd.AdSenseForSearchPageLevelParameters>;

		export type Block = Readonly<ShoAd.AdSenseForSearchUnitLevelParameters>;

		export namespace Block {
			export type OneOrMore = readonly [Parameters.Block, ...ReadonlyArray<Parameters.Block>];
		}
	}

	export type Window = { [Api.globalKey]?: Api | undefined };
}

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends Api.Window {}
}
