import React from 'react';
import { Controlled } from './Controlled';
import * as namespace from './.namespace';

/** Calculates Adnami midscroll banner offset based on element heights. */
export function Inferred({ dependencies, elementSelectors }: Inferred.Props) {
	const offset = Inferred.use(elementSelectors, dependencies);
	return <Controlled offset={offset} />;
}

export namespace Inferred {
	export const displayName = namespace.Midscroll.nameof({ Inferred });

	export type Props = {
		/** Array of CSS selectors which heights combined generate the midscroll offset */
		elementSelectors: Array<string>;

		/** Supply this if the selectors are dependent on runtime before they are rendered */
		dependencies: Array<unknown>;
	};

	export function use(elementSelectors: Array<string>, dependencies: Array<unknown> = []) {
		const [offset, setOffset] = React.useState(0);

		React.useLayoutEffect(() => {
			if (!elementSelectors.length) throw new Error('No element selectors have been specified');

			let totalHeight = 0;

			for (const selector of elementSelectors) {
				const element = document.querySelector(selector);
				const noDependenciesAndElementNotFound = !dependencies.length && !element;

				if (noDependenciesAndElementNotFound) {
					throw new Error(`No dependencies defined, but element ${selector} not found!`);
				}

				const asyncDependenciesHaveTriggeredButElementNotFound =
					dependencies.length &&
					dependencies.every(dependency => dependency !== undefined) &&
					!element;

				if (asyncDependenciesHaveTriggeredButElementNotFound) {
					throw new Error(`Dependencies should be ready, but element ${selector} not found!`);
				}

				if (element) totalHeight += element.clientHeight;
			}

			setOffset(totalHeight);
		}, [...dependencies]);

		return offset;
	}
}
