import * as Core from '../core';

export const emptyModifier = Core.makeBemModifier('empty');

export const slotElement = Core.makeBemElement('adslot');
export const slotEmptyModifier = Core.makeAdSlotModifier(emptyModifier);

export const layoutBlock = Core.makeBemBlock('layout');
export const layoutClass = Core.makeBlockClass(layoutBlock);

export const makeLayoutModifier = Core.modifierClassMaker(layoutClass);
export const layoutEmptyModifier = makeLayoutModifier(emptyModifier);
export const layoutWithWallpaperModifier = makeLayoutModifier('with-wallpaper');

export const makeLayoutElement = Core.elementClassMaker(layoutBlock);
export const wallpaperElementClass = makeLayoutElement('wallpaper');
export const topElementClass = makeLayoutElement('top');
export const pageElementClass = makeLayoutElement('page');
export const contentElementClass = makeLayoutElement('content');
export const skyscrapersElementClass = makeLayoutElement('skyscrapers');
