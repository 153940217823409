import { Lazy, MaybePromise } from '@smd/utilities';

export abstract class State {
	/**
	 * Fires when the state is destroyed. Is intended to abort any ongoing state operations that are
	 * not part of the setup or destroy processes (for that, use the `abortSignal` parameter in each
	 * of the `executeSetup` and `executeDestroy` methods).
	 *
	 * For example, this signal can be used to cancel fetching ads for slots that have not been
	 * rendered during setup, but at a later stage, e.g. in infinite scroll implementations.
	 */
	get abortSignal() {
		return this.#abortController.signal;
	}

	#abortController = new AbortController();
	#setupInvoked = false;

	async setup(
		/** Fires when the setup process is aborted. */
		abortSignal?: AbortSignal,
	) {
		if (this.#setupInvoked) return;
		if (this.isDestroyed()) return;

		this.#setupInvoked = true;
		await this.executeSetup(abortSignal);
	}

	async destroy(
		/** Fires when the destroy process is aborted. */
		abortSignal?: AbortSignal,
	) {
		if (this.isDestroyed()) return;

		this.#abortController.abort(abortSignal?.reason);
		await this.executeDestroy(abortSignal);
	}

	isSetUp() {
		return this.#setupInvoked;
	}

	isDestroyed() {
		return this.abortSignal.aborted;
	}

	protected abstract executeSetup(abortSignal?: AbortSignal): MaybePromise<void>;
	protected abstract executeDestroy(abortSignal?: AbortSignal): MaybePromise<void>;
}

export namespace State {
	export type Of<TState extends State> = TState | Inactive;

	export class Inactive extends State {
		static readonly #instance = Lazy.of(() => new Inactive());
		static readonly of = this.#instance;

		private constructor() {
			super();
		}

		override async setup() {}
		override async destroy() {}

		protected override executeSetup() {}
		protected override executeDestroy() {}
	}

	export abstract class Active<TOptions extends object> extends State {
		protected readonly options: TOptions;

		protected constructor(options: TOptions) {
			super();
			this.options = options;
		}
	}
}
