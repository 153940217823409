/* eslint-disable @next/next/no-html-link-for-pages */
import styles from './Header.module.scss';
import Bilbasen from '../../components/logos/Bilbasen';
import React from 'react';
import cx from 'classnames';
import rawData from '@/molecules/data/header.json';
import { ConditionKey, checkCondition } from '../../utils/MenuConditions';
import { useUser } from '@/hooks/useUser';
import Image from 'next/image';
import Menu from '@/icons/Menu';
import { User } from '@/icons/User';
import Heart from '@/icons/Heart';
import {
  offset,
  useFloating,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  useTransitionStatus,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
  useTransitionStyles,
} from '@floating-ui/react';
import ChevronRight from '@/icons/ChevronRight';
import { UserDetailsDto } from 'api/search';
import {
  PulseStandardEventType,
  trackPulseUIElementEvent,
} from '@smd/tracking';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import Button from '@/atoms/Button';
import AnnounceIcon from '@/icons/AnnounceIcon';

type MenuItem = {
  label?: string;
  icon?: string;
  condition?: ConditionKey;
  className?: string;
};

type Link = {
  type: 'Link';
  href: string;
  target?: string;
} & MenuItem;

type Menu = {
  type: 'Menu' | 'UserMenu';
} & MenuItem;

type Favorites = {
  type: 'Favorites';
  href: string;
} & MenuItem;

type Feedback = {
  type: 'Feedback';
} & MenuItem;

type HeaderActions = {
  id: string;
  elements: Array<Link | Menu | Favorites | Feedback>;
};

type LinkGroup = {
  label: string;
  links: Link[];
};

type HeaderData = {
  header: HeaderActions[];
  menu: LinkGroup[];
  usermenu: Link[];
};

const headerData = rawData as HeaderData;

const trackLink = async (event: React.MouseEvent<HTMLAnchorElement>) => {
  const target = event.currentTarget;
  if (!target) {
    console.log('Unable to track link, no target');
    return;
  }

  event.preventDefault();
  await new Promise((res) => {
    setTimeout(() => res({}), 500);
    try {
      trackPulseUIElementEvent({
        eventType: PulseStandardEventType.Click,
        elementName: target.innerText,
      }).then(res);
    } catch (err: unknown) {
      // If tracking fails, just resolve
      res(null);
    }
  });

  !!target.target
    ? window.open(target.href, target.target)
    : window.location.assign(target.href);
};

const handleFeedback = () => {
  if (!!window && typeof (window as any).hj !== 'undefined') {
    (window as any).hj('event', 'show_beta_survey_manual');
  } else {
    alert(
      'Der opstod desværre en fejl.\r\n\r\nBemærk at vi bruger HotJar til at indsamle feedback, hvilket kan være blokeret af din browser.'
    );
  }
};

export const Header = () => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const { isAuthenticated, user } = useUser();

  React.useEffect(() => {
    const tm = setTimeout(() => {
      if (!!window && typeof (window as any).hj !== 'undefined') {
        setShowFeedback(true);
      }
    }, 2000);
    return () => clearTimeout(tm);
  }, []);

  const renderComponent = (
    elm: Link | Menu | Favorites | Feedback,
    key: string
  ) => {
    // If condition is set, check if it is satisfied
    if (
      !!elm.condition &&
      !checkCondition(elm.condition, {
        isAuthenticated,
        isHotJarLoaded: showFeedback,
      })
    ) {
      return null;
    }

    switch (elm.type) {
      case 'Favorites':
        return (
          <FavoriteMenu key={key} trackLink={trackLink} user={user} elm={elm} />
        );
      case 'Link':
        return (
          <a
            data-e2e={'link-' + key}
            key={key}
            href={elm.href}
            target={elm.target}
            className={cx(
              styles.action,
              !!elm.className && styles[elm.className]
                ? styles[elm.className]
                : elm.className
            )}
            onClick={trackLink}
          >
            <span className={cx({ [styles.text]: !!elm.icon })}>
              {elm.label}
            </span>
            {elm.icon && <Icon className={styles.icon} name={elm.icon} />}
          </a>
        );
      case 'Menu':
        return <MenuButton key={key} />;
      case 'UserMenu':
        return !!user ? (
          <UserProfileButton key={key} user={user.userProfile} />
        ) : null;
      case 'Feedback':
        return (
          <Button
            variant="Label"
            data-e2e="feedback-button"
            endElement={<AnnounceIcon height={24} width={24} />}
            className={cx(styles.action, styles.feedbackButton)}
            onClick={handleFeedback}
          >
            <span className={styles.text}>Feedback</span>
            <span className={styles.longText}>Giv feedback</span>
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <header className={styles.header}>
      <a href="/" className={styles.logo} data-e2e="bb-logo">
        <Bilbasen />
      </a>
      <div className={styles.actions}>
        {headerData.header.map((headerAction) => (
          <div key={headerAction.id}>
            {headerAction.elements.map((elm, index) =>
              renderComponent(elm, index + '')
            )}
          </div>
        ))}
      </div>
    </header>
  );
};

const Icon = ({ name, className }: { name: string; className?: string }) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={`/bas-srp-site/icons/${name}.svg`}
      alt={name}
      height={24}
      width={24}
      className={className}
    />
  );
};

const MenuButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  const { isMounted, status } = useTransitionStatus(context, { duration: 250 });
  const { styles: overlayStyles } = useTransitionStyles(context);

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={styles.action}
        data-e2e="menu-button"
      >
        <span className={styles.text}>Menu</span>
        {isOpen ? (
          <LargeCloseIcon
            width={24}
            height={24}
            className={styles.icon}
            color={'#ff4b00'}
          />
        ) : (
          <Menu
            width={24}
            height={24}
            className={styles.icon}
            fill={'#4F5864'}
          />
        )}
      </button>
      <FloatingPortal id="__next">
        {isMounted && (
          <FloatingOverlay
            lockScroll={true}
            className={styles.overlay}
            style={overlayStyles}
          >
            <FloatingFocusManager context={context}>
              <div
                className={styles.menu}
                ref={refs.setFloating}
                {...getFloatingProps()}
                data-status={status}
              >
                {headerData.menu.map((group) => (
                  <React.Fragment key={group.label}>
                    <h4 key={group.label} className={styles.menuHeader}>
                      {group.label}
                    </h4>
                    <ul>
                      {group.links.map((link) => (
                        <li key={link.href}>
                          <a
                            href={link.href}
                            target={link.target}
                            onClick={trackLink}
                          >
                            <span>{link.label}</span>
                            <ChevronRight height={24} width={24} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  );
};

const UserProfileButton = ({
  user,
}: {
  user: UserDetailsDto['userProfile'];
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: -20 })],
  });

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
    click,
  ]);

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={styles.action}
        data-e2e="user-button"
      >
        <span className={styles.text}>
          {user?.firstName ?? user?.fullName ?? 'Ukendt'}
        </span>
        <User
          width={24}
          height={24}
          color={'#ff4b00'}
          className={styles.icon}
        />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <ul
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
            className={styles.subMenu}
          >
            {headerData.usermenu.map((link) => (
              <li key={link.href}>
                <a
                  href={link.href}
                  target={link.target}
                  className={styles.subMenuItem}
                  onClick={trackLink}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </FloatingFocusManager>
      )}
    </>
  );
};

type FavoriteMenuProps = {
  elm: Favorites;
  trackLink: (event: React.MouseEvent<HTMLAnchorElement>) => any;
  user?: UserDetailsDto;
};

const FavoriteMenu = ({ elm, trackLink, user }: FavoriteMenuProps) => {
  const [animate, setAnimate] = React.useState(false);
  const count = React.useRef<number | null>(user?.favorites?.count ?? null);

  React.useEffect(() => {
    if (!user) {
      setAnimate(false);
      return;
    }

    if (
      typeof count.current === 'number' &&
      count.current !== user?.favorites?.count
    ) {
      setAnimate(true);
      count.current = user?.favorites?.count ?? null;
    }
  }, [user, user?.favorites?.count]);

  return (
    <a
      href={elm.href}
      className={styles.action}
      onClick={trackLink}
      data-e2e="favorite-link"
    >
      <span className={styles.text}>{elm.label}</span>
      <div
        className={cx(styles.favoritesIcon, {
          [styles.animateFavorites]: animate,
        })}
        key={user?.favorites?.count ?? '0'}
      >
        <Heart
          width={24}
          height={24}
          color={
            !!user?.favorites && (user.favorites?.count ?? 0) > 0
              ? '#ff4b00'
              : '#4F5864'
          }
          className={styles.icon}
        />
        {!!user?.favorites && (user.favorites?.count ?? 0) > 0 && (
          <span>
            {user.favorites?.count > 99 ? '99+' : user.favorites.count}
          </span>
        )}
      </div>
    </a>
  );
};
