import './index.css';
import React from 'react';
import type * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../core';
import { Service } from '../Service';
import * as namespace from '../.namespace';

export function Slot({
	properties,
	className,
	role = Slot.Props.Defaults.role,
	'aria-roledescription': ariaRoleDescription = Slot.Props.Defaults.ariaRoleDescription,
	...props
}: Slot.Props) {
	const slot = Slot.use(properties);

	if (!slot.enabled) return null;

	return (
		<div
			{...props}
			{...slot.props}
			title={'Reklame'}
			role={role}
			aria-roledescription={ariaRoleDescription}
			className={Core.injectClass(Core.adSlotClass, Slot.CssClass.modifier, className)}
		/>
	);
}

export namespace Slot {
	export const displayName = namespace.Adnuntius.nameof({ Slot });

	export type Props = Omit<Props.IntrinsicJsxElement, 'children' | 'id'> & {
		properties: Props.Properties;
	};

	export namespace Props {
		export type IntrinsicJsxElement = JSX.IntrinsicElements['div'];
		export type Properties = Core.Properties.TypeSafe<ShoAd.AdType.AdnuntiusDisplay>;
	}

	export namespace Props.Defaults {
		export const role = Core.A11Y_WCAG_STANDARD_FOR_ADS.role;
		export const ariaRoleDescription = Core.A11Y_WCAG_STANDARD_FOR_ADS['aria-roledescription'];
	}

	export function use(properties: Props.Properties) {
		const activate = Service.Context.use();
		const enabled = Core.useSafelyRenderAdSlot();
		const { targetId: id } = properties.adnuntiusDisplay;
		const props = { id } as const satisfies Props.IntrinsicJsxElement;

		Core.useBrowserLayoutEffect(() => {
			if (enabled) activate(id);
		}, [enabled, activate]);

		return { enabled, props } as const;
	}
}

export namespace Slot.CssClass {
	export const modifier = Core.makeAdSlotModifier('adnuntius');
}
