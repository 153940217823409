import './index.css';
import React from 'react';
import * as Core from '../../../core';
import * as Display from '../../Display';
import * as namespace from '../.namespace';

const blockClass = Core.makeBlockClass('audience');

export const Audience = Display.Position.Builder()
	.asNamed(Core.AdPosition.Audience)
	.withComponent(function Audience({ adSlot, props: { className, ...props } }) {
		return (
			<Display.Slot
				{...props}
				properties={adSlot.item}
				className={Core.injectClass(blockClass, className)}
			/>
		);
	})
	.build();

Audience.displayName = namespace.Position.nameof({ Audience });
