import type { Nullable } from '@smd/utilities';
import * as Core from '../../../core';
import type * as Tracking from '../../../tracking';
import { TypeGuard } from '../../TypeGuard';
import type { Api } from '../Api';
import * as AdUnits from './AdUnits';

export function from({
	trackingManager,
	adTypeProperties: pageProperties,
	intermingleItems,
	isSuccess,
	positionItems,
	consentString,
}: from.Options) {
	if (!isSuccess) return null;
	if (!TypeGuard.Properties.Page.has(pageProperties)) return null;

	const adUnits = Array.from(
		AdUnits.from(
			trackingManager,
			pageProperties.adnuntiusDisplay,
			...positionItems,
			...intermingleItems,
		),
	);

	if (!adUnits.length) return null;

	return {
		useCookies: false,
		isolateFrame: true,
		gdpr: 1,
		...(consentString && { consentString }),
		adUnits,
		onError(error) {
			Core.log.error('ADNUNTIUS', 'Callback', 'onError', '', { error });
		},
	} as const satisfies Api.Config;
}

export namespace from {
	export type Options = Core.Config & {
		trackingManager: Nullable<Tracking.Manager>;
		consentString?: string;
	};
}
