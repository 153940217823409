export type PageMeta = Readonly<{
	brand: PageMeta.Brand;
	platform: PageMeta.Platform;
}>;

export namespace PageMeta {
	export type Brand = 'dba' | 'bilbasen';

	export type Platform = 'desktop' | 'web';
}

import * as _Context from './Context';
import * as _fromDataLayer from './fromDataLayer';
import * as _use from './use';

export namespace PageMeta {
	export import Context = _Context.Context;
	export import fromDataLayer = _fromDataLayer.fromDataLayer;
	export import use = _use.use;
}
