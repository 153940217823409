/** Represents the state of the current row. */
export class RowState {
	#offset;
	#columnsCount;
	#value;

	constructor(
		/** The offset of the first item in the list. Used for pagination. */
		offset: number,

		/** The number of columns in the grid layout. */
		columnsCount: number,
	) {
		this.#offset = offset;
		this.#columnsCount = columnsCount;
		this.#value = Math.ceil(offset / columnsCount);
	}

	/** The current row number. */
	get current() {
		return this.#value;
	}

	/** Moves to the next row if the current column is the last column. */
	ifLastColumnMoveToNextRow(intermingleIndex: number) {
		// add one to the currentRow, once we get to the end of the columns
		const currentIndex = intermingleIndex + this.#offset;
		this.#value += (currentIndex + 1) % this.#columnsCount === 0 ? 1 : 0;
	}
}
