import type { MeridianDataLayerValue } from '@smd/datalayer-typings';

export function fromDataLayer(dataLayer: MeridianDataLayerValue | null) {
	const splitted = dataLayer?.p?.pl?.toLowerCase().split('-');
	if (!splitted) return null;

	const [brand, platform] = splitted;
	if (!(brand && platform)) return null;

	return {
		brand,
		platform: platform === 'mweb' ? 'web' : platform,
	} as import('.').PageMeta;
}
