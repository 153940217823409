import { as, nameof } from '@smd/utilities';
import React from 'react';

export type ExtendedContext<T> = React.Context<T> &
	Required<Pick<React.Context<T>, 'displayName'>> & {
		defaultValue: T;
		use(): T;
	};

export namespace ExtendedContext {
	export function of<T>(
		defaultValue: T,
		namespace: nameof.Namespace = { nameof },
	): ExtendedContext<T> {
		const Context: ExtendedContext<T> = as(React.createContext(defaultValue));

		Context.displayName = namespace.nameof({ Context });
		Context.defaultValue = defaultValue;
		Context.use = () => React.useContext(Context);

		return Context;
	}
}
