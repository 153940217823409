export const Facebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h32v32H0V0Zm21.15 13.28-.47 3.61h-3.11v9.27h-3.73v-9.27h-3.12v-3.61h3.12v-2.66c0-3.09 1.88-4.77 4.64-4.77.932-.004 1.863.042 2.79.14v3.23h-1.91c-1.5 0-1.79.76-1.79 1.76v2.3h3.58Z"
    />
  </svg>
);

Facebook.displayName = 'Facebook';
export default Facebook;
