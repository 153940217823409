import React from 'react';
import * as Core from '../../../core';
import * as Tracking from '../../../tracking';
import { from } from './from';

export function use(enabled: boolean) {
	const config = Core.Config.Context.use();
	const trackingManager = Tracking.Context.use();
	const consentString = Core.TCData.use.tcString();

	return React.useMemo(() => {
		try {
			if (!enabled) return use.withoutAds;
			if (!consentString) return use.withoutAds;

			const maybeConsentString = consentString !== Core.TCData.use.NoValue && { consentString };

			const optionsWithAds = from({
				...config,
				...maybeConsentString,
				trackingManager,
			});

			// Rather than using any random options without ads next, we'll use a
			// stable reference to the same empty options avoid unnecessary re-renders:
			return optionsWithAds ?? use.withoutAds;
		} catch (error) {
			Core.log.error('CONFIGURATION', 'AdProvider', 'Adnuntius', 'Options could not be created', {
				error,
			});
		}

		return use.withoutAds;
	}, [enabled, trackingManager, config, consentString]);
}

export namespace use {
	/** When options are not provided, use `null`. */
	export const withoutAds = null;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	export type withoutAds = typeof withoutAds;
}
