import React from 'react';
import * as Ads from '@smd/advertising';
import type { Nullable } from '@smd/utilities';

const intermingleCount = Number(process.env.NEXT_PUBLIC_PAGESIZE ?? 30);

export function AdProvider({
  columnsCount,
  children,
  dehydratedConfig,
  enableManually,
}: AdProvider.Props) {
  const adnami = {
    midscroll: { offset: columnsCount === 1 ? 81 : 0 },
  } satisfies Ads.Core.Adnami.Settings;

  return (
    <Ads.Provider
      enableManually={enableManually}
      endpoint={'/api/sda-publicapi/'}
      intermingleCount={intermingleCount}
      columnsCount={columnsCount}
      withAdnuntiusDisplay={{ adnami }}
      withDisplay={{ adnami }}
      dehydratedConfig={dehydratedConfig}
    >
      {children}
    </Ads.Provider>
  );
}

export namespace AdProvider {
  export type Props = React.PropsWithChildren<{
    enableManually?: boolean;
    columnsCount: number;
    dehydratedConfig?: Nullable<Ads.Core.Config>;
  }>;
}
