import { post, Debouncer } from '@smd/utilities';
import type { Nullable, PickOptional, RequireValues } from '@smd/utilities';
import type * as _ShoAd from '@smd/sho-advertising-typings';
import { getMeridianDataLayerOrNull, type MeridianDataLayerValue } from '@smd/datalayer-typings';
import type { TrackingPlugin } from '@smd/tracking';
import { log, DataLayer } from '../../core';

export class ShoAd implements TrackingPlugin<_ShoAd.TrackingEvent> {
	static readonly #defaultConfig = {
		getMeridianDataLayer: getMeridianDataLayerOrNull,
	} as const satisfies RequireValues<PickOptional<ShoAd.Config>>;

	readonly #abortController;
	readonly #config;
	readonly #debouncer;

	constructor(config: ShoAd.Config) {
		this.#config = {
			...ShoAd.#defaultConfig,
			...config,
		};

		this.#abortController =
			typeof AbortController !== 'undefined' ? new AbortController() : undefined;

		this.#debouncer = new Debouncer<_ShoAd.TrackingEvent>(16, this.#abortController?.signal);

		this.#runTrackingLoop().catch((error: unknown) => {
			log.error('MONITORING', 'ShoAd', 'Event', 'Tracking loop broke!', { error });
		});
	}

	destroy() {
		this.#abortController?.abort();
	}

	async handleEvent(event: _ShoAd.TrackingEvent) {
		this.#debouncer.add(event);
		return Promise.resolve();
	}

	async #runTrackingLoop() {
		for await (const eventsChunk of this.#debouncer) {
			this.#trackEvents(eventsChunk).catch((error: unknown) => {
				log.error('MONITORING', 'ShoAd', 'Event', 'Tracking failed', { error });
			});
		}
	}

	async #trackEvents(events: Iterable<_ShoAd.TrackingEvent>) {
		const [dataLayerLazyPromise] = DataLayer.get(this.#config.getMeridianDataLayer);
		const meridianDataLayer = await dataLayerLazyPromise;

		const payload = {
			...meridianDataLayer,
			trackingEvents: Array.from(events),
		} as const satisfies ShoAd.Payload;

		const signal = this.#abortController?.signal;

		await post(this.#config.endpoint, payload, {
			keepalive: true,
			...(signal && { signal }),
		});
	}
}

export namespace ShoAd {
	export type Payload = Pick<_ShoAd.TrackingRequest, 'trackingEvents'> & MeridianDataLayerValue;

	export type Config = {
		getMeridianDataLayer?: () => Nullable<MeridianDataLayerValue>;
		endpoint: string;
	};
}
