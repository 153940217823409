/** Intermingles listings, items, and ads into a new list. */
export function* intermingle<
	TListing,
	TAd extends intermingle.Item,
	TContent extends intermingle.Item,
>({
	columnsCount = 1,
	intermingle: { ads = [], content = [] },
	listings,
	offset = 0,
	prioritize = 'ads',
	pushRemainingAds = false,
}: intermingle.Options<TListing, TAd, TContent>): Generator<
	TListing | TAd | TContent,
	void,
	undefined
> {
	const rowState = new intermingle.RowState(offset, columnsCount);
	const adsQueue = intermingle.Queue.byType(ads, offset, rowState.current);
	const contentQueue = intermingle.Queue.byType(content, offset, rowState.current);

	const intermingledByIndex = intermingle.byIndex(
		listings,
		adsQueue.indexBased,
		contentQueue.indexBased,
		offset,
		prioritize,
		pushRemainingAds,
	);

	let intermingledRowsCount = 0;

	yield* intermingle.byRow(
		intermingledByIndex,
		adsQueue.rowBased,
		contentQueue.rowBased,
		rowState,
		() => void ++intermingledRowsCount,
		prioritize,
		pushRemainingAds,
	);
}

export namespace intermingle {
	export type Options<TListing, TAd extends intermingle.Item, TContent extends intermingle.Item> = {
		columnsCount?: number;
		intermingle: Options.Intermingle<TAd, TContent>;
		listings: ReadonlyArray<TListing>;
		offset?: number;
		prioritize?: 'ads' | 'content';
		pushRemainingAds?: boolean;
	};

	export namespace Options {
		export type Intermingle<TAd extends intermingle.Item, TContent extends intermingle.Item> = {
			ads?: ReadonlyArray<TAd>;
			content?: ReadonlyArray<TContent>;
		};
	}
}

import * as _byIndex from './byIndex';
import * as _byRow from './byRow';
import * as _Compare from './Compare';
import * as _Item from './Item';
import * as _Queue from './Queue';
import * as _RowState from './RowState';

export namespace intermingle {
	export import byIndex = _byIndex.byIndex;
	export import byRow = _byRow.byRow;
	export import Compare = _Compare;
	export import Item = _Item;
	export type Item = _Item.Item;
	export import Queue = _Queue;
	export import RowState = _RowState.RowState;
}
