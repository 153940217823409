import type { EmptyObject } from '@smd/utilities';
import type * as ShoAd from '@smd/sho-advertising-typings';

export type Config = Config.Empty | Config.Failed | Config.Completed;

export namespace Config {
	export type Completed = Readonly<
		ShoAd.AdResponse & {
			columnsCount: number;
			isEmpty: boolean;
			isFailed: false;
			isSuccess: true;
			isCompleted: true;
		}
	>;

	export type Failed = Readonly<{
		adTypeProperties: Readonly<EmptyObject>;
		intermingleItems: readonly [];
		positionItems: readonly [];
		columnsCount: null;
		isEmpty: true;
		isFailed: true;
		isCompleted: true;
		isSuccess: false;
	}>;

	export type Empty = Readonly<{
		adTypeProperties: Readonly<EmptyObject>;
		intermingleItems: readonly [];
		positionItems: readonly [];
		columnsCount: null;
		isEmpty: true;
		isFailed: false;
		isCompleted: false;
		isSuccess: false;
	}>;
}

import * as _Context from './Context';
import * as _from from './from';
import * as _use from './use';

export namespace Config {
	export import Context = _Context.Context;
	export import from = _from.from;
	export import use = _use.use;
}
