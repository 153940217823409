import type { EmptyObject } from '@smd/utilities';

export type Config = Readonly<{
	customEvents?: Config.CustomEvents;
	outOfPageSlots?: ReadonlyArray<Config.OutOfPageSlot>;
	path: string;
	sizeMappings?: Config.SizeMappings;
	slots: ReadonlyArray<Config.Slot>;
	targeting: Config.Common.Targeting;
}>;

export namespace Config {
	export type WhenEmpty = Extract<EmptyObject, Config>;

	export namespace Common {
		export type Targeting = { readonly [key: string]: string | Array<string> };
	}

	export type CustomEvents = { readonly [key: string]: CustomEvents.CustomEvent };

	export namespace CustomEvents {
		export type CustomEvent = Readonly<{
			divIdPrefix?: string;
			eventMessagePrefix: string;
		}>;
	}

	export type OutOfPageSlot = Readonly<{
		id: string;
		path?: string;
	}>;

	export type SizeMappings = {
		readonly [name: string]: SizeMappings.SizeMapping;
	};

	export namespace SizeMappings {
		export type SizeMapping = ReadonlyArray<
			Readonly<{
				viewportSize: googletag.SingleSizeArray;
				sizes: googletag.GeneralSize;
			}>
		>;
	}

	export type Slot = Readonly<{
		collapseEmptyDiv?: Slot.CollapseEmptyDiv;
		id: string;
		path?: string;
		sizeMappingName?: string;
		sizes: googletag.GeneralSize;
		targeting: Common.Targeting;
	}>;

	export namespace Slot {
		/**
		 * For handling reservation of whitespace for unfilled ad slots.
		 * The goal is to avoid the UI jumping too much dependent on ad delivery.
		 *
		 * #### collapseUnfilled (1st boolean):
		 * Upon confirmed no delivery for ad slot.
		 * - `true` → collapse div (remove the reserved whitespace)
		 * - `false` → don't collapse div (keep the reserved whitespace)
		 *
		 * #### collapseBeforeAdFetch (2nd boolean):
		 * Before ad is requested for ad slot.
		 * - `true` → collapse div (don't reserve whitespace for ad slot)
		 * - `false` → don't collapse div (reserve whitespace for ad slot)
		 *
		 * #### Further details:
		 * @see {@link https://developers.google.com/publisher-tag/reference#googletag.Slot_setCollapseEmptyDiv}
		 */
		export type CollapseEmptyDiv =
			| readonly [collapseUnfilled: false, collapseBeforeAdFetch: false]
			| readonly [collapseUnfilled: true, collapseBeforeAdFetch: boolean];
	}
}

import * as _from from './from';
import * as _Slots from './Slots';
import * as _use from './use';

export namespace Config {
	export import from = _from.from;
	export import Slots = _Slots;
	export import use = _use.use;
}
