import React from 'react';
import { Helmet } from 'react-helmet-async';
import { nameof } from '@smd/utilities';
import { PulseLoader } from '@smd/tracking';

export const PulseTracking = React.memo(
  ({ staticPageView }: PulseTracking.Props) => (
    <PulseLoader
      clientId={'bilbasen'}
      gtmSetting={{
        pulseSkipGtmPageView: true,
        pulseSkipGtmSdk: true,
        pulseSkipGtmTracking: true,
      }}
      schema={'http://schema.schibsted.com/events/tracker-event.json/348.json'}
      {...(staticPageView ? { staticPageView } : { staticPageView })}
    >
      {({ renderDefaultElements }: PulseLoader.Render.Default.Parameters) => (
        <Helmet>{renderDefaultElements()}</Helmet>
      )}
    </PulseLoader>
  ),
  () => true
);

PulseTracking.displayName = nameof({ PulseTracking });

export namespace PulseTracking {
  export type Props = { staticPageView: boolean };
}
