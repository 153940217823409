export const Instagram = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    {...props}
  >
    <path d="M16.02 12.64a3.06 3.06 0 1 0 0 6.12 3.06 3.06 0 0 0 0-6.12Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73 10.73a3 3 0 0 1 3-3h10.53a3 3 0 0 1 3 3v10.53a3 3 0 0 1-3 3H10.73a3 3 0 0 1-3-3V10.73Zm3.23 4.97a5.06 5.06 0 1 1 10.12 0 5.06 5.06 0 0 1-10.12 0Zm12.33-5.06a1.42 1.42 0 1 1-2.84 0 1.42 1.42 0 0 1 2.84 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h32v32H0V0Zm10.73 5.73a5 5 0 0 0-5 5v10.53a5 5 0 0 0 5 5h10.53a5 5 0 0 0 5-5V10.73a5 5 0 0 0-5-5H10.73Z"
    />
  </svg>
);

Instagram.displayName = 'Instagram';
export default Instagram;
