import React from 'react';
import * as ShoAd from '@smd/sho-advertising-typings';
import type * as Core from '../../core';
import * as Adnuntius from '../../adnuntius';
import * as AdManager from '../../ad-manager';
import * as namespace from './.namespace';

/**
 * Handles variance between Ad Manager and Adnuntius ad slots.
 */
export function Slot({ properties, ...props }: Slot.Props) {
	return properties.type === ShoAd.AdType.Display ? (
		<AdManager.Slot {...{ ...props, properties }} />
	) : (
		<Adnuntius.Slot {...{ ...props, properties }} />
	);
}

export namespace Slot {
	export const displayName = namespace.Display.nameof({ Slot });

	export type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'id'> & {
		properties: Core.Properties.TypeSafe<ShoAd.AdType.Display | ShoAd.AdType.AdnuntiusDisplay>;
	};
}
