export type Item = Item.IndexBased | Item.RowBased;

export namespace Item {
	export type IndexBased = { intermingleIndex: number };

	export namespace IndexBased {
		/** Type guard for items that are intermingled by index. */
		export function is(item: Item): item is IndexBased {
			return 'intermingleIndex' in item;
		}
	}

	export type RowBased = { intermingleRow: number };

	export namespace RowBased {
		/** Type guard for items that are intermingled by row. */
		export function is(item: Item): item is RowBased {
			return 'intermingleRow' in item;
		}
	}
}
