import type React from 'react';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Tracking from '@smd/tracking';
import type * as Core from '../../core';
import { Context } from '../Context';

export function use(
	elementRef: React.RefObject<HTMLElement>,
	adType: Core.AdType.Supported,
	container: string,
	properties?: ShoAd.AdSlotProperties,
) {
	const trackingManager = Context.use();

	const handleClick = () => {
		trackingManager?.handleEvent(
			{
				eventType: ShoAd.AdCountEvent.Click,
				adType,
				container,
			},
			properties,
		);
	};

	Tracking.useClickCallback(elementRef, handleClick);
}
