import type { MeridianDataLayerValue } from '@smd/datalayer-typings';

export namespace ConsentAware {
	/**
	 * With `meridianDataLayer.p.pl` present, we assume the dataLayer has
	 * sufficient data for ads.
	 */
	export const from = (consented: boolean) =>
		function validate({ p: page, d: device, u: user }: MeridianDataLayerValue) {
			// We always require platform and assume that page then includes all page
			// data necessary for resolution of advertising strategies. This is the
			// absolute minimum requirement for the dataLayer to be considered:
			if (!page?.pl) return false;

			// If the user has not consented then we have no more requirements. Any
			// parts added to the dataLayer later per-definition contains only
			// user-specific data, which we can't use anyway without consent:
			if (!consented) return true;

			// If the user has consented then we need to wait for user-specific data
			// to be present before we can consider the dataLayer ready for ads. We
			// assume it's been added based on the user cookie or PPID being present:
			return Boolean(device?.ck || user?.ppid);
		};
}

export namespace Basic {
	/**
	 * A basic validator that only requires the platform and page type to be present
	 * in the dataLayer. Should be used for no-consent scenarios, where we can't
	 * rely on user-specific data, and for fallback scenarios.
	 */
	export function validate({ p: page }: MeridianDataLayerValue) {
		return Boolean(page?.pl && page.t);
	}
}
