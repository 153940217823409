import { Item } from './Item';
import * as Compare from './Compare';

/** Groups the intermingle items into two arrays based on their type. */
export function byType<TItem extends Item>(
	items: ReadonlyArray<TItem>,
	offset: number,
	currentRow: number,
): byType<TItem & Item.RowBased, TItem & Item.IndexBased> {
	const rowBased = new Array<TItem & Item.RowBased>();
	const indexBased = new Array<TItem & Item.IndexBased>();

	for (const item of items) {
		if (Item.RowBased.is(item)) {
			if (item.intermingleRow >= currentRow) rowBased.push(item);
			continue;
		}

		if (Item.IndexBased.is(item)) {
			if (item.intermingleIndex >= offset) indexBased.push(item);
			continue;
		}
	}

	return {
		rowBased: rowBased.sort(Compare.byRow),
		indexBased: indexBased.sort(Compare.byIndex),
	} as const;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type byType<
	TRowBased extends Item.RowBased,
	TIndexBased extends Item.IndexBased,
> = Readonly<{
	rowBased: Array<TRowBased>;
	indexBased: Array<TIndexBased>;
}>;
