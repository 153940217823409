import React from 'react';
import * as Core from '../../core';
import * as Providers from './Providers';

/**
 * Returns the supported ad types for the given options.
 */
export function* from(options: Providers.Options) {
	for (const adType of Core.AdType.Supported) {
		if (!options[Providers.Options.Name.from(adType)]) continue;
		yield adType;
	}
}

/**
 * A hook that returns an array of supported ad types based on the provided options.
 */
export function use(options: Providers.Options) {
	return React.useMemo(() => Array.from(from(options)), [options]);
}
