import type { AnyNonNullable } from '@smd/utilities';
import { getMeridianDataLayerOrNull } from '@smd/datalayer-typings';
import * as Core from '../../../core';
import { Api } from '../Api';

export class Ppid extends Core.Service.Generic.State.Active<Ppid.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		const ppid = getMeridianDataLayerOrNull()?.u?.ppid;

		if (!ppid) {
			Core.log.warn('CONFIGURATION', 'GPT', 'Missing PPID');
			return;
		}

		await Api.execute(
			function () {
				Core.log('CONFIGURATION', 'GPT', 'Setting PPID', ppid);
				this.pubads().setPublisherProvidedId(ppid);
			},
			this.abortSignal,
			abortSignal,
		);
	}

	protected override executeDestroy() {}
}

export namespace Ppid {
	export type Options = Readonly<AnyNonNullable>;
}
