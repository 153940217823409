import type { Nullable } from '@smd/utilities';
import * as Core from '../../core';
import * as namespace from './.namespace';
import { Api } from './Api';
import type { State } from './State';

/**
 * Resets some internal state in Relevant Yield and destroys the current page context and prepares for the next page for the active ad servers.
 * Provided here (as opposed to in {@linkcode executeDestroy}) since it depends on {@linkcode Api.destroySlots} from Relevant Yield.
 * @see {@link https://help.relevant-digital.com/knowledge/relevantdigital.destroyslots}
 */
async function cleanupAfterPageView(abortSignal?: AbortSignal) {
	await Api.execute(function () {
		this.destroySlots();
	}, abortSignal);
}

export const Context = Object.assign(Core.ExtendedContext.of<Context>(null, namespace.Service), {
	cleanupAfterPageView,
} as const);

export type Context = Nullable<
	Readonly<{
		runAuction: State.Active['runAuction'];
		cleanupAfterPageView: typeof cleanupAfterPageView;
		execute: typeof Api.execute;
	}>
>;

export namespace Context {
	export type Auction = Api.LoadPrebid.OnAuctionDone.Parameters & {
		readonly winningTakeoverDealId: Nullable<string>;
	};
}
