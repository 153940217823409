import { injectClass, makeBemBlock, makeBlockClass, modifierClassMaker } from '../Styles';
import type { PropsMapper } from './PropsMapper';

export const adPositionBlock = makeBemBlock('position');

export const adPositionClass = makeBlockClass(adPositionBlock);

export const makeAdPositionElement = modifierClassMaker(adPositionClass);

export const injectPositionClassNames: PropsMapper = (
	{ positionName },
	{ className, ...props },
) => ({
	...props,
	className: injectClass(
		adPositionClass,
		// Class name becomes fx `nmp-ads-position--wallpaper`:
		makeAdPositionElement(positionName),
		className,
	),
});
