import type React from 'react';
import type * as ShoAd from '@smd/sho-advertising-typings';
import type * as Core from '../../core';
import * as Click from './Click';
import * as Impression from './Impression';
import * as ViewableImpression from './ViewableImpression';

export function use(
	elementRef: React.RefObject<HTMLElement>,
	adType: Core.AdType.Supported,
	container: string,
	properties?: ShoAd.AdSlotProperties,
) {
	Click.use(elementRef, adType, container, properties);
	Impression.use(elementRef, adType, container, properties);
	ViewableImpression.use(elementRef, adType, container, properties);
}
