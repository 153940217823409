import * as Core from '../../../../core';
import type { Api } from '../../Api';
import type { EventForwarder } from './EventForwarder';

export function from(
	configId: string,
	allowedDivIds: ReadonlyArray<string>,
	eventForwarder: EventForwarder,
) {
	return {
		allowedDivIds,
		configId,
		manageAdserver: false,
		noGpt: true,
		noSlotReload: false,

		createAdUnitCode({ slot }) {
			return slot.getSlotElementId();
		},

		googletagCalls: {
			refresh(...parameters) {
				/*
					By not forwarding this call to `googletag.pubads().refresh()`, we enable
					ourselves instead of Relevant Yield to handle the refresh when we please.
				*/

				eventForwarder.collect('refresh', withSafeLogging => {
					const [slots] = parameters;

					withSafeLogging((type, callbackName) => {
						const count = slots?.length || 'all';
						Core.log(type, callbackName, `ads (count: ${count})`, '', { parameters });
					});

					return slots;
				});
			},
		},

		onBeforeAuctionSetup(parameters) {
			eventForwarder.collect('onBeforeAuctionSetup', withSafeLogging => {
				withSafeLogging((type, callbackName) => {
					Core.log(type, callbackName, `auction (id: ${parameters.auction.auctionId})`, '', {
						parameters,
					});
				});

				return parameters;
			});
		},

		onAuctionDone(parameters) {
			eventForwarder.collect('onAuctionDone', withSafeLogging => {
				withSafeLogging((type, callbackName) => {
					const allBids = parameters.auction.hbaAuction.allBids();

					const bidsWithResponses = allBids
						.filter(({ noBid }) => !noBid)
						.filter(bid => 'bidResponse' in bid && bid.bidResponse);

					const bidsOverview = `requests: ${allBids.length}, responses: ${bidsWithResponses.length}`;

					const unitIds = parameters.auction.usedUnitDatas
						.map(({ slot }) => slot.getSlotElementId())
						.join(', ');

					Core.log(type, callbackName, `bids (${bidsOverview})`, unitIds, {
						allBids,
						bidsWithResponses,
						parameters,
					});
				});

				return parameters;
			});
		},

		onAuctionInitDone(parameters) {
			eventForwarder.collect('onAuctionInitDone', withSafeLogging => {
				withSafeLogging((type, callbackName) => {
					const unitsCount = parameters.auction.usedUnitDatas.length.toString();

					const unitIds = parameters.auction.usedUnitDatas
						.map(({ slot }) => slot.getSlotElementId())
						.join(', ');

					Core.log(type, callbackName, `units (${unitsCount})`, unitIds, { parameters });
				});

				return parameters;
			});
		},

		onBeforeAdRequest(parameters) {
			eventForwarder.collect('onBeforeAdRequest', withSafeLogging => {
				withSafeLogging((type, callbackName) => {
					Core.log(type, callbackName, `auction (id: ${parameters.auction.auctionId})`, '', {
						parameters,
					});
				});

				return parameters;
			});
		},

		onSlotAndUnit(parameters) {
			eventForwarder.collect('onSlotAndUnit', withSafeLogging => {
				withSafeLogging((type, callbackName) => {
					Core.log(type, callbackName, `slot (id: ${parameters.slot.getSlotElementId()})`, '', {
						parameters,
					});
				});

				return parameters;
			});
		},
	} as const satisfies Api.LoadPrebid.Parameters;
}
