import './index.css';
import React from 'react';
import { noop } from '@smd/utilities';
import type * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../core';
import { Service } from '../Service';
import * as namespace from '../.namespace';

export function Slot({
	properties,
	className,
	role = Slot.Props.Defaults.role,
	'aria-roledescription': ariaRoleDescription = Slot.Props.Defaults.ariaRoleDescription,
	...props
}: Slot.Props) {
	const slot = Slot.use(properties);

	if (!slot.enabled) return null;

	return (
		<div
			{...props}
			{...slot.props}
			title={'Reklame'}
			role={role}
			aria-roledescription={ariaRoleDescription}
			className={Core.injectClass(Core.adSlotClass, Slot.CssClass.modifier, className)}
		/>
	);
}

export namespace Slot {
	export const displayName = namespace.AdManager.nameof({ Slot });

	export type Props = Omit<Props.IntrinsicJsxElement, 'children' | 'id'> & {
		properties: Props.Properties;
		// customEventHandlers?: Service.State.CustomEvents.Handler.Map;
	};

	export namespace Props {
		export type IntrinsicJsxElement = JSX.IntrinsicElements['div'];
		export type Properties = Core.Properties.TypeSafe<ShoAd.AdType.Display>;
	}

	export namespace Props.Defaults {
		export const role = Core.A11Y_WCAG_STANDARD_FOR_ADS.role;
		export const ariaRoleDescription = Core.A11Y_WCAG_STANDARD_FOR_ADS['aria-roledescription'];
	}

	export function use(properties: Props.Properties) {
		const activate = Service.Context.use();
		const deactivateRef = React.useRef<ReturnType<typeof activate>>();
		const enabled = Core.useSafelyRenderAdSlot();
		const { id } = properties.display;
		const props = { id } as const satisfies Props.IntrinsicJsxElement;

		Core.useBrowserLayoutEffect(() => {
			if (enabled) deactivateRef.current = activate(id);
		}, [enabled, activate]);

		// If the component gets unmounted, deactivate the ad slot:
		Core.useBrowserLayoutEffect(() => () => void deactivateRef.current?.().catch(noop), []);

		return { enabled, props } as const;
	}
}

export namespace Slot.CssClass {
	export const modifier = Core.makeAdSlotModifier('admanager');
}
