import type { Nullable } from '@smd/utilities';
import { Api } from '../Api';
import type { Config } from '../Config';
import type { Active } from './Active';
import type { SizeMapping } from './SizeMapping';

export namespace Slot {
	export namespace Default {
		export async function from(
			fallBackPath: string,
			{ collapseEmptyDiv, id, sizes, sizeMappingName, targeting, path = fallBackPath }: Config.Slot,
			sizeMapping: SizeMapping,
			...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
		) {
			return await Api.execute(
				function (): googletag.Slot {
					const slot = this.defineSlot(path, sizes, id);

					if (sizeMappingName) {
						const sizeMappingArray = sizeMapping.get(sizeMappingName);
						if (sizeMappingArray) slot.defineSizeMapping(sizeMappingArray);
					}

					if (collapseEmptyDiv) {
						const [collapseUnfilled, collapseBeforeAdFetch] = collapseEmptyDiv;
						slot.setCollapseEmptyDiv(collapseUnfilled, collapseBeforeAdFetch);
					}

					for (const [key, values] of Object.entries(targeting)) slot.setTargeting(key, values);

					slot.addService(this.pubads());

					return slot;
				},
				...abortSignals,
			);
		}
	}

	export namespace OutOfPage {
		export async function from(
			fallBackPath: string,
			{ id, path = fallBackPath }: Config.OutOfPageSlot,
			...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
		) {
			return await Api.execute(
				function (): googletag.Slot {
					const slot = this.defineOutOfPageSlot(path, id);
					slot.addService(this.pubads());

					return slot;
				},
				...abortSignals,
			);
		}
	}

	export async function from(
		{ slots, outOfPageSlots, path }: Active.Options,
		slotId: string,
		sizeMapping: SizeMapping,
		...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
	) {
		const config = slots.find(slot => slot.id === slotId);

		if (config) {
			return await Default.from(path, config, sizeMapping, ...abortSignals);
		}

		const outOfPageConfig = outOfPageSlots?.find(slot => slot.id === slotId);

		if (outOfPageConfig) {
			return await OutOfPage.from(path, outOfPageConfig, ...abortSignals);
		}

		return null;
	}
}
