import React from 'react';
import { getTCData, type TCData } from '@smd/cmp-tcf';
import { AsyncEffect } from './utils';
import { log } from './utils/log';

export function use() {
	const [tcData, setTCData] = React.useState<TCData | use.NoValue | null>(null);

	AsyncEffect.use(
		() => ({
			effect: async ({ abortSignal }) => {
				try {
					const tcData = await getTCData();
					if (abortSignal.aborted) return;
					setTCData(tcData);
				} catch (error) {
					log.error('GDPR', 'CMP', 'getTCData', 'Failed!', { error });
					if (abortSignal.aborted) return;
					setTCData(use.NoValue);
				}
			},
		}),
		[setTCData],
	);

	return tcData;
}

export namespace use {
	export const NoValue = Symbol('Failed');

	export type NoValue = typeof NoValue;

	export function tcString() {
		const tcData = use();
		if (!tcData) return null;
		if (tcData === NoValue) return NoValue;
		return tcData.tcString;
	}
}

export namespace Log {
	export function use() {
		AsyncEffect.use(
			() => ({
				effect: async () => {
					if (!log.isEnabled()) return;

					try {
						const tcData = await getTCData();
						log('GDPR', 'CMP', tcData.eventStatus, '__tcfapi', tcData);
					} catch (error) {
						log.error('GDPR', 'CMP', 'Log TCData', 'Failed!', { error });
					}
				},
			}),
			[],
		);
	}
}
