import React from 'react';
import { Context } from './Context';
import * as Api from './Api';
import * as namespace from './.namespace';

/**
 * A component that provides the display ads utilities.
 */
export function Provider({ children, enabled }: Provider.Props) {
	return <Context.Provider value={Api.use(enabled)}>{children}</Context.Provider>;
}

export namespace Provider {
	export const displayName = namespace.Display.nameof({ Provider });

	export type Props = {
		children: React.ReactNode;
		enabled: boolean;
	};
}
