import type { IsNever } from '@smd/utilities';
import { TrackingManager } from '@smd/tracking';
import type { Context } from './Context';
import * as Plugin from './Plugin';

export type Manager = Context;

/**
 * Use this one when you just want to use all advertising tracking
 * plugins with default configurations.
 */
export namespace Manager {
	export function from({ shoAdConfig }: Config) {
		return new TrackingManager({
			plugins: [new Plugin.Console(), new Plugin.ShoAd(...shoAdConfig)],
		});
	}

	export type Config = {
		[TPluginName in PluginName as HasParams<TPluginName, true>]: ConstructorParameters<
			Plugin[TPluginName]
		>;
	};

	type HasParams<TPluginName extends PluginName, THasParams extends boolean> =
		ConstructorParameters<Plugin[TPluginName]> extends []
			? Name<TPluginName, THasParams & false>
			: Name<TPluginName, THasParams & true>;

	type Name<TPluginName extends PluginName, THasParams extends boolean> =
		IsNever<THasParams> extends false ? `${Uncapitalize<TPluginName>}Config` : never;

	type Plugin = typeof Plugin;
	type PluginName = keyof typeof Plugin;
}
