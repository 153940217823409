import Footer from './Footer';
import Head from 'next/head';
import * as Ads from '@smd/advertising';
import { Header } from '@/molecules/Header';

export default function Layout({ children }: any) {
  return (
    <>
      <Head>
        <title>Bilbasen</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Se alle brugte biler til salg på Bilbasen - Danmarks største bilmarked. Søg billige biler og sammenlign priser fra både forhandlere og private."
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://billeder.bilbasen.dk" />
      </Head>
      <Header />
      <Ads.Layout.Wrapper {...adsLayoutProps}>
        <div className="container">
          <main>{children}</main>
          <Footer />
        </div>
      </Ads.Layout.Wrapper>
    </>
  );
}

const maxContentWidth = 960;
const skyscraperMinimumPageMargin = 160; // half width of two small skyscrapers

const adsLayoutProps = {
  maxContentWidth: `${maxContentWidth}px`,
  breakpoints: {
    mediumScreen: '600px',
    largeScreen: `${maxContentWidth + skyscraperMinimumPageMargin}px`,
  },
} as const satisfies Ads.Layout.Wrapper.Props;
