import React from 'react';
import { compareDeep, type Nullable } from '@smd/utilities';
import type {
  MeridianDataLayerValue,
  PulseDataLayerValue,
} from '@smd/datalayer-typings';

/**
 * The latest received values of the dataLayer, indexed by equality comparison
 * scope.
 *
 * It's set it here - at the module level as opposed to the hook level - to
 * manage the value across all usages of the {@link useSyncToDataLayer} hook.
 * This mitigates the risk of pushing a redundant value for each instance of
 * each component that (in)directly references {@link useSyncToDataLayer}.
 */
const valueCache = {} as Record<symbol, useSyncToDataLayer.Value>;

/**
 * Pushes new values to {@link window.dataLayer}. To avoid triggering redundant
 * change events from pushing redundant data, only distinct values will be pushed.
 * That is, data that's equal by value, but not necessarily by reference.
 */
export function useSyncToDataLayer(
  /** A value to be pushed to {@link window.dataLayer}. */
  next: useSyncToDataLayer.Value,

  /**
   * A scope used for equality comparison. Can be applied to avoid comparison of
   * unrelated data (e.g. Meridian and Pulse), or only a subset of the data (e.g.
   * user-specific Meridian data).
   */
  equalityComparisonScope = Symbol.for('DataLayer:Default')
) {
  React.useEffect(() => {
    // If no next dataLayer is provided, skip:
    if (!next) return;

    const valuesAreEqual = compareDeep(
      valueCache[equalityComparisonScope],
      next,
      { matchAnyCasingForStrings: true, trimStrings: true }
    );

    // Update the reference to potentially avoid some future value-by-value
    // equality comparisons:
    valueCache[equalityComparisonScope] = next;

    // If they each contain the same values, skip:
    if (valuesAreEqual) return;

    // Nice, we got new data! Let's push it to the global dataLayer:
    (window.dataLayer ??= []).push(next);
  }, [next, equalityComparisonScope]);
}

export namespace useSyncToDataLayer {
  export type Value = Nullable<MeridianDataLayerValue | PulseDataLayerValue>;
}
