import { IncomingHttpHeaders } from 'http';
import { Car as GalleryCar } from 'components/frontpage/Gallery';
import fallbackImg from '../public/missing.svg';
import {baseURL as srpBaseURL, UserDetailsDto} from './search';
import {
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpModelsSearchUriRequest,
  SmdBasSrpWebApiModelsWebSearchResponseFrontpage,
  type EcgdkTrackingDataLayerDtoDataLayerDto,
  type EcgdkTrackingPulseDataLayerDtoPulseDataLayerDto,
} from 'types/api';
import { get, post } from './common';

export type ExtraPromotedCarDto = Car;
export type NewCarDto = Car;

type Car = {
  links: Array<{
    rel: string;
    hRef: string;
    gaTrackingText: string | null;
    globalId: string | null;
  }>;
  priceText: string;
  text: string;
};

export type KlikXtraCarDto = {
  adId: number;
  category: string;
  createdDate: string;
  globalId: number;
  klikXtra: boolean;
  make: string;
  milage: string;
  model: string;
  pageId: number;
  picture: string;
  pictureCount: number;
  price: string;
  source: number;
  title: string;
  url: string;
  variant: string;
  year: number;
  image: string;
};

const isServerSide = typeof window === 'undefined';

const baseURL = new URL(
  isServerSide
    ? process.env.API_FRONTPAGE_ENDPOINT + '/api/frontpage/'
    : window.location.origin + '/api/frontpage/'
);

export const newsletterSignUp = (email: string) =>
  post(
    new URL(
      `newsletter/confirmation?emailAddress=${encodeURIComponent(email)}`,
      baseURL
    )
  );

export interface BlogPost {
  image: string;
  text: string;
  category: string;
  blogPostTrackKey: string;
  categoryTrackKey: string;
  blogPostUri: string;
  categoryUri: string;
}

export interface Partner {
  requiresBridgeTracking: boolean;
  layoutType: number;
  position: number;
  heading: string;
  provider: string;
  textLines: string[];
  action: Action;
  logo: Action;
  image: Action;
}

export interface Action {
  rel: string;
  hRef: string;
  method: null;
  caption: null | string;
  secondaryCaption: null | string;
  screen: null;
  gaTrackingText: null;
}

export const getPartner = async (
  headers?: IncomingHttpHeaders
): Promise<Partner> => {
  return get<Partner>(new URL('getpartner', baseURL), null, headers);
};

export const getBlogPosts = async (
  headers?: IncomingHttpHeaders
): Promise<BlogPost[]> => {
  const blogData = await get<BlogPost[]>(
    new URL('blog', baseURL),
    null,
    headers
  );

  return blogData;
};

export const getDatalayer = async (
  headers?: IncomingHttpHeaders
) => {
  return await get<getDatalayer.Result>(new URL('getdatalayer', baseURL), null, headers);
};

export namespace getDatalayer {
  export type Result = {
    gtm?: Result.Gtm;
    pulseDataLayerDto?: Result.PulseDataLayerDto;
  };

  export namespace Result {
    export type Gtm = { dataLayer: EcgdkTrackingDataLayerDtoDataLayerDto };
    export type PulseDataLayerDto = EcgdkTrackingPulseDataLayerDtoPulseDataLayerDto;
  }
} 

async function GetCars(
  page: number = 0,
  pageSize: number = 8,
  endpoint: URL
): Promise<Car[]> {
  const res: Response = await fetch(
    `${endpoint}?page=${page}&pagesize=${pageSize}`,
    {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'GET',
      },
    }
  );

  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json().then((d) => d.items);
}

export async function GetExtraPromotedCars(
  page: number = 1,
  pageSize: number = 8
): Promise<ExtraPromotedCarDto[]> {
  return GetCars(page, pageSize, new URL('extrapromoted', baseURL));
}

export async function GetNewestCars(
  page: number = 1,
  pageSize: number = 4
): Promise<ExtraPromotedCarDto[]> {
  return GetCars(page, pageSize, new URL('newest', baseURL));
}

export async function GetKlikXtraCars(
  page: number = 0,
  pageSize: number = 12,
  endpoint: URL = new URL('klikxtra', baseURL)
): Promise<KlikXtraCarDto[]> {
  const res: Response = await fetch(
    `${endpoint}?pagenumber=${page}&pagesize=${pageSize}`,
    {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'GET',
      },
    }
  );

  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
}

export async function GetXtraCars(
  page: number = 1,
  pageSize: number = 4
): Promise<KlikXtraCarDto[]> {
  return GetKlikXtraCars(page, pageSize, new URL('klikxtra', baseURL));
}

export const carToGalleryCar = (car: Car) =>
  ({
    image:
      car.links.find((d) => d.rel === 'Image')?.hRef ??
      fallbackImg.src.toString(),
    title: car.text,
    price: car.priceText,
    url: car.links.find((d) => d.rel === 'Self')?.hRef,
  } as GalleryCar);

export const KlikXtraCarToGalleryCar = (car: KlikXtraCarDto) => ({
  image: car.picture ?? fallbackImg.src.toString(),
  title: `${car.make} ${car.model}, modelår ${car.year}, ${car.milage} km`,
  price: car.price,
  url: car.url,
});

export const getSearchFiltersByUrl = async (
  uri?: string,
  signal?: RequestInit['signal'],
  headers?: IncomingHttpHeaders
) => {
  const body: SmdBasSrpModelsSearchUriRequest = uri ? { searchUri: uri } : {}; // If there is no uri provided, get the default filters with default values;
  return post<
    SmdBasSrpWebApiModelsWebSearchResponseFrontpage,
    SmdBasSrpModelsSearchUriRequest
  >(new URL('frontpage/by-request', srpBaseURL), body, signal, headers);
};

export const getSearchFiltersByRequest = (
  parameters: SmdBasSrpModelsSearchRequest,
  signal: RequestInit['signal']
) =>
  post<
    SmdBasSrpWebApiModelsWebSearchResponseFrontpage,
    SmdBasSrpModelsSearchRequest
  >(new URL('frontpage/by-request', srpBaseURL), parameters, signal);

export const getAbundance = async () =>
  get<number>(
    new URL(
      'total?apikey=B3BAB31C-ED9B-4E40-8C2A-8C6627C1C685',
      process.env.API_ABUNDANCE_ENDPOINT
    )
  );

export const getUserDetails = async (headers?: IncomingHttpHeaders) => {
  return get<UserDetailsDto>(new URL('userdata/props', baseURL), null, headers);
}

