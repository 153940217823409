/**
 * Defines the context object for menu conditions.
 */
export type ConditionContext = {
  isAuthenticated: boolean;
  isHotJarLoaded: boolean;
};

/**
 * Defines a collection of menu conditions.
 */
const Conditions = {
  /**
   * Checks if the user is logged in.
   * @param context - The condition context.
   * @returns A boolean indicating whether the user is logged in.
   */
  isLoggedIn: (context: ConditionContext) => context.isAuthenticated,

  /**
   * Checks if the user is not logged in.
   * @param context - The condition context.
   * @returns A boolean indicating whether the user is not logged in.
   */
  isNotLoggedIn: (context: ConditionContext) => !context.isAuthenticated,

  /**
   * Checks if HotJar is available.
   * @param context - The condition context.
   * @returns A boolean indicating whether HotJar is available.
   */
  isHotJarAvailable: (context: ConditionContext) => context.isHotJarLoaded,
};

/**
 * Represents a key of the `Conditions` object.
 */
export type ConditionKey = keyof typeof Conditions;

/**
 * Checks a single condition.
 * @param condition - The condition key.
 * @param context - The condition context.
 * @returns A boolean indicating whether the condition is met.
 */
export const checkCondition = (
  condition: ConditionKey,
  context: ConditionContext
) => {
  if (!Conditions[condition]) {
    console.log('Unknown condition', condition);
    return false;
  }
  return Conditions[condition](context);
};

/**
 * Checks multiple conditions.
 * @param conditions - An array of condition keys.
 * @param context - The condition context.
 * @returns A boolean indicating whether all conditions are met.
 */
export const checkConditions = (
  conditions: ConditionKey[],
  context: ConditionContext
) => {
  const conditionResults = conditions.map((condition) =>
    checkCondition(condition, context)
  );

  // Currently all conditions must be true, but we could add support for .some() as well
  return conditionResults.every((result) => result);
};
