import React from 'react';
import * as namespace from '../.namespace';

/**
 * On small screens, parts of the Adnami midscroll ad may be hidden behind floating elements,
 * e.g. a page header. This component attemps to correct that by offsetting the ad by a certain
 * amount of pixels.
 */
export function Midscroll(props: Midscroll.Props) {
	return 'offset' in props ? (
		<Midscroll.Controlled {...props} />
	) : (
		<Midscroll.Inferred {...props} />
	);
}

export namespace Midscroll {
	export const displayName = namespace.Adnami.nameof({ Midscroll });

	export type Props = Controlled.Props | Inferred.Props;
}

import * as _Controlled from './Controlled';
import * as _Inferred from './Inferred';
import * as _Styles from './Styles';

export namespace Midscroll {
	export import Controlled = _Controlled.Controlled;
	export import Inferred = _Inferred.Inferred;
	export import Styles = _Styles;
}
