import React from 'react';
import * as Core from '../../core';
import { Slot } from './Slot';
import { TypeGuard } from './TypeGuard';
import * as namespace from './.namespace';

/** This function is used to create a common display position builder. */
const Builder = () =>
	Core.AdPositionBuilder.from(TypeGuard.Slot.select)
		.setElementType<HTMLDivElement>()
		.mapProps(Core.injectPositionClassNames);

export const Position = Object.assign(
	Builder()
		.withComponent(function Position({ adSlot, props }) {
			return <Slot {...props} properties={adSlot.item} />;
		})
		.build(),
	{ Builder },
);

Position.displayName = namespace.Display.nameof({ Position });
