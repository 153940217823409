export const AnnounceIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="m3.634 12.746.431-1.145-1.007-2.675a.916.916 0 0 1 .347-1.079L5.77 6.283l.738-2.763a.905.905 0 0 1 .338-.495.886.886 0 0 1 .569-.171l2.822.145L12.44 1.2a.886.886 0 0 1 1.122 0L15.76 3l2.823-.143c.203-.01.404.05.57.172a.905.905 0 0 1 .337.495l.738 2.763 2.366 1.564a.915.915 0 0 1 .347 1.079l-1.006 2.675 1.006 2.673a.915.915 0 0 1-.347 1.08l-2.366 1.56-.742 2.763a.904.904 0 0 1-.337.496.884.884 0 0 1-.57.172l-2.823-.142-2.175 1.773M1.248 18.371l2.495 3.743"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.79 16.872 3 21l-1-1.5L11.158 8l5.632 8.872ZM10.5 7l7 11M6 20.316l.876 1.346a2.042 2.042 0 0 0 2.906.614 2.256 2.256 0 0 0 .587-3.04"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

AnnounceIcon.displayName = 'AnnounceIcon';
export default AnnounceIcon;
