import type * as ShoAd from '@smd/sho-advertising-typings';
import { as } from '@smd/utilities';
import { TypeGuard } from '../../TypeGuard';
import type { Config } from '.';

export function* from(...adSlots: ReadonlyArray<ShoAd.IAdSlot>) {
	for (const adSlot of adSlots) {
		if (!TypeGuard.Slot.is(adSlot)) continue;

		const { collapseEmptyDiv, prebid, ...slot } = adSlot.item.display;
		void prebid;

		// Backend still returns size, which makes Google Ad Manager unhappy, thus we delete it:
		if ('size' in slot) delete slot.size;

		yield {
			...slot,

			// We assume it's correctly structured by backend, so we just cast it
			// to the correct type:
			collapseEmptyDiv: as(collapseEmptyDiv),
		} as const satisfies Config.Slot;
	}
}
