import './index.css';
import React from 'react';
import * as Core from '../../../core';
import * as Display from '../../Display';
import * as Utilities from '../../Utilities';
import * as Styles from '../../Styles';
import * as namespace from '../.namespace';

const blockClass = Core.makeBlockClass('wallpaper');
const makeElement = Core.elementClassMaker(blockClass);
const adSlotElementClass = makeElement(Styles.slotElement);
const iframeElementClass = makeElement('iframe');
const readyClass = Core.modifierClassMaker(blockClass)('ready');
const buildProperties = Core.buildCssVariableProperties(blockClass);

export const Wallpaper = Display.Position.Builder()
	.asNamed(Core.AdPosition.Wallpaper)
	.withComponent(function Wallpaper({ adSlot, props: { className, ...props } }) {
		const wallpaper = Utilities.Display.Context.Wallpaper.use();
		const regular = 'imgUrl' in wallpaper;
		const media = 'mediaWallpaperUrl' in wallpaper;

		Core.useBrowserLayoutEffect(() => {
			if (regular || media) {
				document.body.classList.add(Styles.layoutWithWallpaperModifier);
			} else {
				document.body.classList.remove(Styles.layoutWithWallpaperModifier);
			}

			return () => {
				document.body.classList.remove(Styles.layoutWithWallpaperModifier);
			};
		}, [regular, media]);

		const onClick = React.useCallback(() => {
			if (regular && wallpaper.impressionUrl && wallpaper.clickUrl) {
				window.open(`${wallpaper.impressionUrl}${wallpaper.clickUrl}`, '_blank');
			}
		}, [wallpaper]);

		const style = regular
			? buildProperties(when => ({
					imgUrl: when(wallpaper.imgUrl).then(Core.buildCssUrl),
					cursor: when(wallpaper.clickUrl).then('pointer'),
				}))
			: undefined;

		return (
			<div
				className={Core.injectClass(blockClass, (regular || media) && readyClass)}
				onClick={onClick}
				style={style}
			>
				<Display.Slot
					{...props}
					properties={adSlot.item}
					className={Core.injectClass(adSlotElementClass, className)}
				/>

				{media && (
					<iframe
						id={'media-banner-wallpaper'}
						className={iframeElementClass}
						src={wallpaper.mediaWallpaperUrl}
					/>
				)}
			</div>
		);
	})
	.build();

Wallpaper.displayName = namespace.Position.nameof({ Wallpaper });
