import './index.css';
import React from 'react';
import { isNotNullOrUndefined } from '@smd/utilities';
import * as Core from '../../../../../../core';
import * as Styles from '../../../../../Styles';
import type { Hook } from '../Hook';

const emptyableLayoutClasses = [
	Styles.skyscrapersElementClass,
	Styles.topElementClass,
	Styles.wallpaperElementClass,
] as const;

/**
 * Sets the ad slot to empty.
 */
export const use: Hook<Setter, []> = () => {
	const config = Core.Config.Context.use();

	Core.useBrowserLayoutEffect(() => {
		if (config.isEmpty) return;

		// Clean up CSS classes added to ad slots that were previously set to empty:
		return () => {
			Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Cleanup', 'Empty');

			for (const slotElement of window.document
				.querySelectorAll(`.${Styles.slotEmptyModifier}`)
				.values()) {
				try {
					slotElement.classList.remove(Styles.slotEmptyModifier);
				} catch {
					// Do nothing, try the next element...
				}
			}

			for (const layoutElement of window.document
				.querySelectorAll(`.${Styles.layoutEmptyModifier}`)
				.values()) {
				try {
					layoutElement.classList.remove(Styles.layoutEmptyModifier);
				} catch {
					// Do nothing, try the next element...
				}
			}
		};
	}, [config]);

	return React.useCallback(
		(
			/** The iframe provided by the creative template. */
			iframe,
		) => {
			const slotElement = iframe.closest<HTMLDivElement>(`.${Core.adSlotClass}`);

			if (slotElement) {
				slotElement.classList.add(Styles.slotEmptyModifier);
			}

			const layoutElement = emptyableLayoutClasses
				.map(elementClass => iframe.closest<HTMLDivElement>(`.${elementClass}`))
				.find(isNotNullOrUndefined);

			if (layoutElement) {
				layoutElement.classList.add(Styles.layoutEmptyModifier);
			}

			Core.log('CONFIGURATION', 'Ads.Utilities.Display', 'Api', 'Empty', {
				slotElement,
				layoutElement,
			});
		},
		[],
	);
};

export type Setter = {
	(iframe: HTMLIFrameElement): void;
};
