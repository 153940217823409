import { canUseDOM } from '@smd/utilities';

// TODO: use ConsoleLogHelper from @smd/utilities
// TODO: use namespaces for logging instead of args

export function log(...args: LogParams) {
	log.info(...args);
}

export namespace log {
	export const info = (...args: LogParams) => logAs('info', ...args);
	export const warn = (...args: LogParams) => logAs('warn', ...args);
	export const error = (...args: LogParams) => logAs('error', ...args);

	const logAs = (kind: Kind, ...args: LogParams) => {
		if (!log.isEnabled()) return;
		const logger = console[kind];
		const [type, param1, param2, param3, data] = args;
		const firstArgs = [type, param1, param2, param3] as const;

		if (typeof data === 'undefined') {
			logger(...LogParams.from(...firstArgs));
			return;
		}

		const adConsoleGroup = group(...firstArgs);
		adConsoleGroup[kind](data);
		adConsoleGroup.end();
	};

	export type Type =
		| 'IMPRESSION'
		| 'LOAD'
		| 'GDPR'
		| 'CONFIGURATION'
		| 'PREBID'
		| 'REQUEST'
		| 'PULSE'
		| 'MONITORING'
		| 'DATALAYER'
		| 'ADSENSE'
		| 'ADMANAGER'
		| 'ADNUNTIUS'
		| 'API';
}

export namespace log {
	export function group(...args: LogParams) {
		if (isEnabled()) {
			const [type, param1, param2, param3, data] = args;
			const firstArgs = [type, param1, param2, param3] as const;

			console.groupCollapsed(...LogParams.from(...firstArgs));

			if (typeof data !== 'undefined') {
				console.info(data);
			}
		}

		return groupLog;
	}

	const logAs =
		(kind: Kind) =>
		(...args: Parameters<(typeof console)[Kind]>) => {
			if (!log.isEnabled()) return;
			console[kind](...args);
		};

	// eslint-disable-next-line no-inner-declarations
	function groupLog(...args: Parameters<typeof groupLog.info>) {
		groupLog.info(...args);
	}

	namespace groupLog {
		export const info = logAs('info');
		export const warn = logAs('warn');
		export const error = logAs('error');

		export const end = () => {
			if (!isEnabled()) return;
			console.groupEnd();
		};
	}
}

export namespace log {
	let enabled = false;

	export const isEnabled = () => {
		if (enabled) return true;

		try {
			return (enabled =
				canUseDOM() &&
				Boolean(
					JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) ?? LoggingState.Off.toString()),
				));
		} catch (e) {
			return false;
		}
	};

	enum LoggingState {
		Off = 0,
		On = 1,
	}

	const STORAGE_KEY = 'Sho.Advertising.Logger';
	const ENABLED_HASH = '#adlog';

	const enabledLogging = () => {
		try {
			canUseDOM() &&
				window.location.hash === ENABLED_HASH &&
				window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(LoggingState.On));
		} catch {
			// Ignore
		}
	};

	if (canUseDOM()) {
		window.addEventListener('hashchange', enabledLogging, false);
	}
}

type Kind = 'info' | 'warn' | 'error';

type LogParams = Parameters<typeof LogParams.from>;

namespace LogParams {
	export function from(
		type: log.Type,
		param1: string,
		param2: string | number = '',
		param3: string | number = '',
		data?: object,
	) {
		return [
			`%c${type}: %c${param1} %c${param2} %c${param3}`,
			'color:hotpink',
			'color:skyblue',
			'color:khaki',
			'color:lightgreen',
			...(typeof data !== 'undefined' ? [data] : []),
		] as const;
	}
}
