import React from 'react';
import * as Core from '../../core';
import * as namespace from './.namespace';

export class Service extends Core.Service<null, Service.State, Service.Context> {
	static readonly #from = () => new this(null);
	static readonly use = super.Generic.Hook.from(this.#from);
	static readonly Provider = super.Generic.Provider.from(this, namespace);

	override readonly logType: Core.log.Type = 'PREBID';
	override readonly Context = Service.Context;

	override useRender(enabled: boolean, children: React.ReactNode) {
		const pageMeta = Core.PageMeta.Context.use();

		Core.useBrowserLayoutEffect(() => {
			if (!pageMeta) return;
			Service.Api.setScriptUrl(ScriptUrl => ScriptUrl.fromPageMeta(pageMeta));
		}, [Boolean(pageMeta)]);

		return super.useRender(enabled, children);
	}

	override useState(enabled: boolean) {
		const config = Service.Config.use(enabled);

		return React.useMemo(
			() => (config ? Service.State.Active.from(config) : this.Inactive),
			[config],
		);
	}

	override useMapStateToContextValue(state: Service.State) {
		const execute = React.useMemo(() => Service.Api.execute.bind(Service.Api), []);

		return React.useMemo(() => {
			if (state instanceof Service.Generic.State.Inactive) return Service.Context.defaultValue;

			return {
				runAuction: state.runAuction.bind(state),
				cleanupAfterPageView: Service.Context.cleanupAfterPageView,
				execute,
			} satisfies Service.Context;
		}, [state, execute]);
	}
}

export namespace Service {
	export namespace Provider {
		export type Props = Core.Service.Generic.Provider.Props;
	}
}

import * as _Api from './Api';
import * as _Config from './Config';
import * as _Context from './Context';
import * as _State from './State';

export namespace Service {
	export import Api = _Api.Api;
	export import Config = _Config.Config;
	export import Context = _Context.Context;
	export import State = _State.State;
}
