import type * as ShoAd from '@smd/sho-advertising-typings';
import type * as Core from '../../core';
import * as AdManager from '../../ad-manager';
import * as Adnuntius from '../../adnuntius';

export type AdType = ShoAd.AdType.Display | ShoAd.AdType.AdnuntiusDisplay;

const Properties = {
	/** Type guard for ad slot properties. */
	is(properties) {
		return (
			AdManager.TypeGuard.Properties.is(properties) || Adnuntius.TypeGuard.Properties.is(properties)
		);
	},
} as Core.TypeGuard.Properties<AdType>;

const Slot = {
	/** Type guard for ad slot. */
	is(slot) {
		return Properties.is(slot.item);
	},

	/** Selects a slot by position name. */
	select(name) {
		return position => position.positionName === name && Slot.is(position);
	},
} as Core.TypeGuard.Slot<AdType>;

export const TypeGuard = { Properties, Slot } as const satisfies Core.TypeGuard<AdType>;
