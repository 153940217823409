import { isNullOrUndefined } from '@smd/utilities';
import type { ValidCssVariableValue } from './buildCssVariableProperties';

export function cssValueHelper<TDependency>(dependency: TDependency | null | undefined) {
	return {
		then: <TValue extends ValidCssVariableValue>(
			mapValue: TValue | ((dependency: TDependency) => TValue),
		) =>
			isNullOrUndefined(dependency)
				? null
				: typeof mapValue === 'function'
					? mapValue(dependency)
					: mapValue,
	};
}

export type CssValueHelper = typeof cssValueHelper;
