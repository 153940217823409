import React from 'react';
import * as Core from '../core';
import * as Tracking from '../tracking';
import * as Services from './Services';
import * as Utilities from './Utilities';
import * as namespace from './.namespace';

/**
 * A component provides the necessary context for displaying ads within React.
 */
export function Provider({
	children,
	trackingManager,
	endpoint,
	enableManually,
	intermingleCount,
	columnsCount,
	getMeridianDataLayer,
	dehydratedConfig,
	...supportedAdTypeOptions
}: Provider.Props) {
	Core.TCData.Log.use();

	const supportedAdTypes = Services.AdTypes.use(supportedAdTypeOptions);

	const [config, refresh, pageMeta] = Core.Config.use({
		endpoint,
		intermingleCount,
		...(columnsCount && { columnsCount }),
		getMeridianDataLayer,
		dehydratedConfig,
		supportedAdTypes,
	});

	const [enabled, enable] = Utilities.enable.use(config, enableManually);

	return (
		<Core.Config.Context.Provider value={config}>
			<Core.PageMeta.Context.Provider value={pageMeta}>
				<Tracking.Provider config={{ trackingManager, endpoint }}>
					<Utilities.Provider config={{ supportedAdTypeOptions, enable, refresh }}>
						<Services.Providers.Compiled
							config={{ enabled, supportedAdTypeOptions, supportedAdTypes }}
						>
							{children}
						</Services.Providers.Compiled>
					</Utilities.Provider>
				</Tracking.Provider>
			</Core.PageMeta.Context.Provider>
		</Core.Config.Context.Provider>
	);
}

export namespace Provider {
	export const displayName = namespace.Ads.nameof({ Provider });

	export type Props = Options &
		Services.Providers.Options & {
			/**
			 * Used to opt out of automatic enabling of ads and instead use `Ads.Utilities.enable()`.
			 * Default is `false`, which enables ads automatically.
			 */
			enableManually?: boolean;

			/** The tracking manager to use. If not provided, a default one is used. */
			trackingManager?: Tracking.Manager;

			children: React.ReactNode;
		};

	type Options = Omit<Core.Config.use.Options, 'supportedAdTypes'>;
}
