import { isNullOrUndefined, PickByValue } from '@smd/utilities';
import type { AnyCssClass } from './AnyCssClass';
import { cssValueHelper, CssValueHelper } from './cssValueHelper';
import type { CssVariableName } from './CssVariableName';

export type ValidCssVariableValue = string | number;

export type CssVariableConfigValue = ValidCssVariableValue | null | undefined;

export type CssVariableConfig = {
	[key: string]: CssVariableConfigValue;
};

export type CssVariableProperties<
	TCssClass extends AnyCssClass,
	TCssVariableConfig extends CssVariableConfig,
> = {
	[TVariableName in keyof TCssVariableConfig as TVariableName extends string
		? CssVariableName<TCssClass, TVariableName>
		: never]?: NonNullable<TCssVariableConfig[TVariableName]>;
} & {
	[TVariableName in keyof PickByValue<
		TCssVariableConfig,
		ValidCssVariableValue
	> as TVariableName extends string
		? CssVariableName<TCssClass, TVariableName>
		: never]: NonNullable<TCssVariableConfig[TVariableName]>;
};

export function buildCssVariableProperties<TCssClass extends AnyCssClass>(cssClass: TCssClass) {
	return <
		TCssVariableConfig extends CssVariableConfig,
		TCssVariableProperties extends CssVariableProperties<TCssClass, TCssVariableConfig>,
	>(
		cssVariableConfig: TCssVariableConfig | ((when: CssValueHelper) => TCssVariableConfig),
	) => {
		const config =
			typeof cssVariableConfig === 'function'
				? cssVariableConfig(cssValueHelper)
				: cssVariableConfig;

		return Object.entries(config)
			.map(entry => entry as TConfigEntry)
			.filter(
				(entry): entry is [(typeof entry)[0], NonNullable<(typeof entry)[1]>] =>
					!isNullOrUndefined(entry[1]),
			)
			.reduce((properties, [name, value]) => {
				// eslint-disable-next-line @typescript-eslint/no-base-to-string
				const newName = `--${cssClass}__${name}` as const;

				properties[newName] = value as TCssVariableProperties[typeof newName];

				return properties;
			}, {} as TCssVariableProperties);

		type TConfigKeys = Exclude<keyof TCssVariableConfig, number | symbol>;

		type TConfigEntry = {
			[TKey in TConfigKeys]: [TKey, TCssVariableConfig[TKey]];
		}[TConfigKeys];
	};
}
