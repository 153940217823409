import type { AnyNonNullable } from '@smd/utilities';
import * as Core from '../../../core';
import { Api } from '../Api';

export class LazyLoad extends Core.Service.Generic.State.Active<LazyLoad.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		await Api.execute(
			function () {
				this.pubads().enableLazyLoad(LazyLoad.enabled);
			},
			this.abortSignal,
			abortSignal,
		);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			this.pubads().enableLazyLoad(LazyLoad.disabled);
		}, abortSignal);
	}
}

export namespace LazyLoad {
	export type Options = Readonly<AnyNonNullable>;

	export const enabled = {
		/** Within viewport percentage, 200% means within 2 viewports, -1 means disabled. */
		fetchMarginPercent: 300,
		/** Within viewport percentage, 100% means within 1 viewport */
		renderMarginPercent: 200,
		/** Mobile factor to above values, 1 equals no scaling */
		mobileScaling: 2.0,
	} as const satisfies googletag.LazyLoadOptionsConfig;

	export const disabled = {
		fetchMarginPercent: -1,
		renderMarginPercent: -1,
		mobileScaling: -1,
	} as const satisfies googletag.LazyLoadOptionsConfig;
}
