import type { AdType } from '../AdType';
import type { Position } from '../Position';
import { Config } from '../Config';

export function usePositionAdSlot<TPositionName extends string, TAdType extends AdType.Supported>(
	name: TPositionName,
	selector: Position.Selector<TAdType>,
) {
	const { isSuccess, isEmpty, positionItems: positions } = Config.Context.use();
	if (!isSuccess || isEmpty) return null;

	return positions.find(selector(name)) ?? null;
}
