import * as ShoAd from '@smd/sho-advertising-typings';
import { AdType } from './AdType';
import type { Properties as _Properties } from './Properties';
import type { Slot as _Slot } from './Slot';
import type { Position } from './Position';
import type { Page as _Page } from './Page';
import { uncapitalize } from '@smd/utilities';

export const TypeGuard = AdType.Supported.Keys.reduce(
	(collection, key) =>
		Object.assign(collection, {
			[key]: {
				Properties: {
					is({ type }) {
						return type === ShoAd.AdType[key];
					},
					Page: {
						has(page) {
							return Boolean(page[uncapitalize(ShoAd.AdType[key])]);
						},
					},
				},
				Slot: {
					is({ item }) {
						return collection[key].Properties.is(item);
					},

					select(name) {
						return slot => slot.positionName === name && collection[key].Slot.is(slot);
					},
				},
			} as TypeGuard<typeof key>,
		} as const),
	{} as TypeGuard.Collection,
);

export type TypeGuard<TAdTypeKey extends AdType.Supported.Keys> = Readonly<{
	Properties: TypeGuard.Properties<TAdTypeKey>;
	Slot: TypeGuard.Slot<TAdTypeKey>;
}>;

export namespace TypeGuard {
	export type Collection = Readonly<{
		[TAdTypeKey in AdType.Supported.Keys]: TypeGuard<TAdTypeKey>;
	}>;

	export type Properties<TAdTypeKey extends AdType.Supported.Keys> = Readonly<{
		is: Properties.Is<TAdTypeKey>;
		Page: Properties.Page<TAdTypeKey>;
	}>;

	export namespace Properties {
		export type Is<TAdTypeKey extends AdType.Supported.Keys> = _Properties.Is<
			(typeof ShoAd.AdType)[TAdTypeKey]
		>;

		export type Page<TAdTypeKey extends AdType.Supported.Keys> = Readonly<{
			has: Page.Has<TAdTypeKey>;
		}>;

		export namespace Page {
			export type Has<TAdTypeKey extends AdType.Supported.Keys> = _Page.Is<
				(typeof ShoAd.AdType)[TAdTypeKey]
			>;
		}
	}

	export type Slot<TAdTypeKey extends AdType.Supported.Keys> = Readonly<{
		is: Slot.Is<TAdTypeKey>;
		select: Slot.Select<TAdTypeKey>;
	}>;

	export namespace Slot {
		export type Is<TAdTypeKey extends AdType.Supported.Keys> = _Slot.Is<
			(typeof ShoAd.AdType)[TAdTypeKey]
		>;

		export type Select<TAdTypeKey extends AdType.Supported.Keys> = Position.Selector<
			(typeof ShoAd.AdType)[TAdTypeKey]
		>;
	}
}
