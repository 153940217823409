import React from 'react';
import * as Core from '../core';
import * as namespace from './.namespace';
import { Slot } from './Slot';
import { TypeGuard } from './TypeGuard';

export const Position = Core.AdPositionBuilder.from(TypeGuard.Slot.select)
	.setElementType<HTMLDivElement>()
	.mapProps(Core.injectPositionClassNames)
	.withComponent(function Position({ adSlot, props }) {
		return <Slot {...props} properties={adSlot.item} />;
	})
	.build();

Position.displayName = namespace.AdSense.nameof({ Position });
