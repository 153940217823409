import type * as Core from '../../../core';

export type State = Core.Service.Generic.State.Of<State.Active>;

import * as _Auction from './Auction';
import * as _Active from './Active';
import * as _Tracking from './Tracking';

export namespace State {
	export import Auction = _Auction;
	export import Active = _Active.Active;
	export import Tracking = _Tracking.Tracking;
}
