import React from 'react';
import { Helmet } from 'react-helmet-async';
import { equals } from 'ramda';
import type { Config } from './Config';
import * as _Styles from '../Styles';
import * as CssVariables from './CssVariables';
import * as namespace from './.namespace';

const className = _Styles.makeLayoutElement('globals');

/**
 * Sets CSS variable constants:
 * - `--nmp-ads-layout__maxContentWidth`
 *
 * Sets CSS toggle variables via media queries:
 * - `--nmp-ads-layout__isSmallScreen`
 * - `--nmp-ads-layout__isNotSmallScreen`
 * - `--nmp-ads-layout__isMediumScreen`
 * - `--nmp-ads-layout__isNotMediumScreen`
 * - `--nmp-ads-layout__isLargeScreen`
 * - `--nmp-ads-layout__isNotLargeScreen`
 *
 * Example on setting color based on screen size:
 * ```css
 * .someClass {
 *   color:
 *     var(--nmp-ads-layout__isSmallScreen, red)
 *     var(--nmp-ads-layout__isMediumScreen, green)
 *     var(--nmp-ads-layout__isLargeScreen, blue);
 * }
 * ```
 */
export const Styles = React.memo<Config>(
	config => (
		<Helmet>
			<style className={className}>{CssVariables.from(config)}</style>
		</Helmet>
	),
	equals,
);

Styles.displayName = namespace.Layout.nameof({ Styles });
