// These headers will be passed from the original request (specifically when doing SSR)
const HEADERS_TO_PASS_ALONG = [
    'user-agent',
    'authentication',
    'authorization',
    'cookie',
    'referer',
    'x-ebay-correlationid',
];

export function curateHeaders(headers?: NodeJS.Dict<string | string[]>) {
    return HEADERS_TO_PASS_ALONG.reduce((prev, curr) => {
        const header = headers?.[curr];
        if(header) 
        {
            prev[curr] = header.toString(); // a string array is serialized as {0},{1} e.g. which is what we want…
        }
        return prev;
    }, {} as Record<string, string>);
}