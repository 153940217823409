import React from 'react';
import type * as Core from '../../core';
import { Context } from './Context';

/**
 * A flag that indicates whether the ads fetching and rendering should be disabled.
 * External dependencies will still be loaded and initialized, and the advertising
 * backend will still be queried for configuration.
 */
let disabled = true;

/**
 * Refreshes all ads on the page. Can be used outside of React.
 *
 * We just forward the call to the `enable` function stored in {@link Context.currentValue},
 * which is updated by the `Provider` component. This way, we synchronize the non-React
 * {@link disabled} state with the actual React state in {@link enable.use}.
 */
export function enable() {
	Context.currentValue.enable();
}

export namespace enable {
	/**
	 * Controls the enabled state of ads fetching and rendering, to be passed to {@link Context}.
	 */
	export function use(config: Core.Config, enableManually: boolean = false) {
		if (disabled && !enableManually) disabled = false;
		const [enabled, setEnabled] = React.useState(!disabled);

		const enable = React.useCallback(() => {
			if (!disabled) return;
			disabled = false;
			setEnabled(true);
		}, []);

		return [enabled && config.isCompleted, enable] as const;
	}

	export namespace use {
		/**
		 * Enables ads fetching and rendering when the condition is met.
		 */
		export function when(condition: unknown) {
			const { enable } = Context.use();

			React.useEffect(() => {
				if (!condition) return;
				enable();
			}, [condition]);
		}
	}
}
