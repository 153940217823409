import * as ShoAd from '@smd/sho-advertising-typings';
import { as, Nullable } from '@smd/utilities';
import * as Core from '../../../core';
import { Api } from '../Api';

export class Tracking extends Core.Service.Generic.State.Active<Tracking.Options> {
	readonly #eventHandlers = [
		['slotRequested', this.#handleSlotRequested.bind(this)],
		['impressionViewable', this.#handleImpressionViewable.bind(this)],
		['slotRenderEnded', this.#handleSlotRenderEnded.bind(this)],
	] as const;

	protected override async executeSetup(abortSignal?: AbortSignal) {
		const eventHandlers = this.#eventHandlers;

		await Api.execute(
			function () {
				for (const [eventType, listener] of eventHandlers) {
					this.pubads().addEventListener(eventType, as(listener));
				}
			},
			this.abortSignal,
			abortSignal,
		);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		const eventHandlers = this.#eventHandlers;

		await Api.execute(function () {
			for (const [eventType, listener] of eventHandlers) {
				this.pubads().removeEventListener(eventType, as(listener));
			}
		}, abortSignal);
	}

	#handleSlotRequested({ slot }: googletag.events.SlotRequestedEvent) {
		Core.log('REQUEST', 'display', slot.getSlotElementId(), slot.getAdUnitPath());
	}

	#handleImpressionViewable({ slot }: googletag.events.ImpressionViewableEvent) {
		this.#trackEvent(slot, ShoAd.AdCountEvent.ViewableImpression);
	}

	#handleSlotRenderEnded({ isEmpty, slot }: googletag.events.SlotRenderEndedEvent) {
		const eventType = isEmpty
			? ShoAd.AdCountEvent.UnfilledImpression
			: ShoAd.AdCountEvent.Impression;

		this.#trackEvent(slot, eventType);
	}

	#trackEvent(slot: googletag.Slot, eventType: ShoAd.AdCountEvent) {
		this.options.trackingManager?.handleEvent({
			adType: ShoAd.AdType.Display,
			container: slot.getSlotElementId(),
			eventType,
		});
	}
}

export namespace Tracking {
	export type Options = Readonly<{
		trackingManager?: Nullable<import('../../../tracking').Manager>;
	}>;
}
