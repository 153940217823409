import type { Item } from './Item';
import * as Compare from './Compare';

/** Intermingles items that are intermingled by index. */
export function* byIndex<
	TListing,
	TIndexBasedAd extends Item.IndexBased,
	TIndexBasedContent extends Item.IndexBased,
>(
	listings: ReadonlyArray<TListing>,
	indexBasedAdsQueue: Array<TIndexBasedAd>,
	indexBasedContentQueue: Array<TIndexBasedContent>,
	offset: number,
	prioritize: 'ads' | 'content',
	pushRemainingAds: boolean,
) {
	const queuesByPriority =
		prioritize === 'ads'
			? [indexBasedAdsQueue, indexBasedContentQueue]
			: [indexBasedContentQueue, indexBasedAdsQueue];

	for (const [index, listing] of listings.entries()) {
		const currentIndex = index + offset;

		for (const queue of queuesByPriority) {
			while (queue[0]?.intermingleIndex === currentIndex) {
				yield queue[0];
				queue.shift();
			}
		}

		yield listing;
	}

	if (pushRemainingAds) {
		yield* queuesByPriority.flat().sort(Compare.byIndex);
		for (const queue of queuesByPriority) queue.length = 0;
	}
}
