import type { Item } from './Item';
import type { RowState } from './RowState';
import * as Compare from './Compare';

/** Intermingles items that are intermingled by row. */
export function* byRow<
	TIndexBasedIntermingle,
	TRowBasedAd extends Item.RowBased,
	TRowBasedContent extends Item.RowBased,
>(
	indexBasedIntermingledItems: Iterable<TIndexBasedIntermingle>,
	rowBasedAdsQueue: Array<TRowBasedAd>,
	rowBasedContentQueue: Array<TRowBasedContent>,
	rowState: RowState,
	countIntermingledRow: () => void,
	prioritize: 'ads' | 'content',
	pushRemainingAds: boolean,
) {
	const queuesByPriority =
		prioritize === 'ads'
			? [rowBasedAdsQueue, rowBasedContentQueue]
			: [rowBasedContentQueue, rowBasedAdsQueue];

	let count = 0;

	for (const indexBasedIntermingledItem of indexBasedIntermingledItems) {
		for (const queue of queuesByPriority) {
			while (queue[0]?.intermingleRow === rowState.current) {
				yield queue[0];
				queue.shift();
				countIntermingledRow();
			}
		}

		yield indexBasedIntermingledItem;

		rowState.ifLastColumnMoveToNextRow(count);
		++count;
	}

	if (pushRemainingAds) {
		yield* queuesByPriority.flat().sort(Compare.byRow);
		for (const queue of queuesByPriority) queue.length = 0;
	}
}
