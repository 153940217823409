export const LargeCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={17}
    width={16}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="m12.5 4-9 9M3.5 4l9 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

LargeCloseIcon.displayName = 'LargeCloseIcon';
export default LargeCloseIcon;
