import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import type * as ShoAd from '@smd/sho-advertising-typings';
import { Nullable, post } from '@smd/utilities';
import { log } from '../utils';
import type { AdType } from '../AdType';
import { Context } from './Context';
import type { Config } from '.';

export async function from({
	abortSignal,
	endpoint,
	dataLayer,
	supportedAdTypes,
	intermingleCount,
	columnsCount,
	headers,
}: from.Options) {
	if (abortSignal?.aborted) return Context.failedValue;

	const listingCount = Math.min(from.MAX_LISTINGS_COUNT_FOR_ADS, intermingleCount);
	const supportedAds = supportedAdTypes.map(adType => ({ adType }));

	const request = {
		dataLayer,
		pagination: {
			listingOffset: 0,
			listingCount,
			listingColumns: columnsCount,
		},
		supportedAds,
	} as const satisfies ShoAd.AdRequest;

	try {
		log('REQUEST', 'API', 'AdRequest', 'Payload', request);

		const response = await post<ShoAd.AdRequest, ShoAd.AdResponse>(`${endpoint}sda`, request, {
			...(headers && { headers }),
			...(abortSignal && { signal: abortSignal }),
		});

		log('CONFIGURATION', 'API', 'Response', 'Config', { response });

		const isEmpty = !(response.intermingleItems.length || response.positionItems.length);

		return {
			...response,
			columnsCount,
			isEmpty,
			isSuccess: true,
			isCompleted: true,
			isFailed: false,
		} as const satisfies Config;
	} catch (error) {
		log.error('REQUEST', 'API', 'Request', 'Failed config fetch', { error });

		return Context.failedValue;
	}
}

export namespace from {
	export const MAX_LISTINGS_COUNT_FOR_ADS = 210;

	export type Options = {
		abortSignal?: Nullable<AbortSignal>;
		endpoint: string;
		dataLayer: MeridianDataLayerValue;
		supportedAdTypes: Array<AdType.Supported>;
		intermingleCount: number;
		columnsCount: number;
		headers?: HeadersInit;
	};
}
