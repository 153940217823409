import React from 'react';
import type { Nullable } from '@smd/utilities';
import * as Tracking from '@smd/tracking';
import type * as ShoAd from '@smd/sho-advertising-typings';
import type * as Core from '../core';
import * as namespace from './.namespace';

export const Context: Core.ExtendedContext<Context> = Object.assign(
	Tracking.createTrackingContext<Context.Event, Context.Args>(),
	{
		displayName: '',
		defaultValue: null,
		use: () => React.useContext(Context),
	},
);

Context.displayName = namespace.Tracking.nameof({ Context });

export type Context = Tracking.TrackingContextValue<Context.Event, Context.Args>;

export namespace Context {
	export type Event = ShoAd.TrackingEvent;
	export type Args = [properties?: Nullable<ShoAd.AdSlotProperties>, data?: unknown];
}
