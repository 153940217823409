import * as Core from '../../../core';
import { Api } from '../Api';
import type { Config } from '../Config';

export class SizeMapping extends Core.Service.Generic.State.Active<SizeMapping.Options> {
	readonly #values: SizeMapping.Values = new Map();

	get(name: string) {
		return this.#values.get(name);
	}

	protected override async executeSetup(abortSignal?: AbortSignal) {
		abortSignal?.throwIfAborted();

		const { sizeMappings } = this.options;
		if (!sizeMappings) return;

		const values = this.#values;

		await Api.execute(
			function () {
				for (const [name, sizeMapping] of Object.entries(sizeMappings)) {
					const builder = this.sizeMapping();

					for (const { viewportSize, sizes } of sizeMapping) {
						builder.addSize(viewportSize, sizes);
					}

					values.set(name, builder.build());
				}
			},
			this.abortSignal,
			abortSignal,
		);
	}

	protected override executeDestroy() {
		this.#values.clear();
	}
}

export namespace SizeMapping {
	export type Values = Map<string, googletag.SizeMappingArray>;
	export type Options = Readonly<{ sizeMappings?: Config.SizeMappings }>;
}
