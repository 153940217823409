export const User = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.997 14.933a5.133 5.133 0 1 0 0-10.266 5.133 5.133 0 0 0 0 10.266Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.578 7.193A8.518 8.518 0 0 0 13.713 9.8a8.53 8.53 0 0 0 3.315-.67M5.238 20.679a9.53 9.53 0 0 1 13.498-.021"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
