import React from 'react';

export function useConstRef<T>(constructor: () => T): T {
	const ref = React.useRef<T | typeof useConstRef.NoValue>(useConstRef.NoValue);

	if (ref.current === useConstRef.NoValue) {
		ref.current = constructor();
	}

	return ref.current;
}

export namespace useConstRef {
	export const NoValue = Symbol('NoValue');
}
