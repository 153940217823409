import './index.css';
import React from 'react';
import * as Core from '../../../core';
import * as Display from '../../Display';
import * as Utilities from '../../Utilities';
import * as Styles from '../../Styles';
import * as namespace from '../.namespace';

const blockClass = Core.makeBlockClass('skyscraper');
const makeModifier = Core.modifierClassMaker(blockClass);
const topModifier = makeModifier('top');
const bottomModifier = makeModifier('bottom');
const leftModifier = makeModifier('left');
const rightModifier = makeModifier('right');
const stickyModifier = makeModifier('sticky');
const adSlotElementClass = Core.elementClassMaker(blockClass)(Styles.slotElement);

export const Skyscraper = Display.Position.Builder()
	.asNamed(
		Core.AdPosition.SkyscraperLeftTop,
		Core.AdPosition.SkyscraperLeftBottom,
		Core.AdPosition.SkyscraperRightBottom,
		Core.AdPosition.SkyscraperRightTop,
		Core.AdPosition.GallerySkyscraperLeft,
		Core.AdPosition.GallerySkyscraperRight,
	)
	.withComponent(function Skyscraper({
		adSlot: { positionName, item },
		props: { className, ...props },
	}) {
		const position = {
			top: !(positionName.includes('-2') || positionName.includes('gallery')),
			left: positionName.includes('-left'),
			sticky: Utilities.Display.Context.IsSticky.use(positionName) ?? true,
		} as const;

		return (
			<div
				className={Core.injectClass(
					blockClass,
					position.top ? topModifier : bottomModifier,
					position.left ? leftModifier : rightModifier,
					position.sticky && stickyModifier,
				)}
			>
				<Display.Slot
					{...props}
					properties={item}
					className={Core.injectClass(adSlotElementClass, className)}
				/>
			</div>
		);
	})
	.build();

Skyscraper.displayName = namespace.Position.nameof({ Skyscraper });
