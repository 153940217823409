import type { Item } from './Item';

/** Compares two intermingle items by their intermingleIndex property. */
export function byIndex<TIndexBased extends Item.IndexBased>(a: TIndexBased, b: TIndexBased) {
	return a.intermingleIndex - b.intermingleIndex;
}

/** Compares two intermingle items by their intermingleRow property. */
export function byRow<TRowBased extends Item.RowBased>(a: TRowBased, b: TRowBased) {
	return a.intermingleRow - b.intermingleRow;
}
