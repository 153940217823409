import React from 'react';
import * as Core from '../../core';
import type { State } from './State';
import { Api } from './Api';

export namespace Config {
	export function from({ adTypeProperties: pageProperties, isSuccess }: from.Options) {
		if (!isSuccess) return null;
		if (!Core.TypeGuard.Display.Properties.Page.has(pageProperties)) return null;

		const { prebid } = pageProperties.display;
		if (!prebid) return null;

		const { ortb2 } = prebid;

		return { ...(ortb2 && { ortb2 }) } as const satisfies State.Active.Options;
	}

	export namespace from {
		export type Options = Core.Config;
	}

	export function use(enabled: boolean) {
		const ready = use.isReady();
		const config = Core.Config.Context.use();

		return React.useMemo(() => {
			try {
				if (!enabled || !ready) return use.withoutAds;

				// Rather than using any random options without ads next, we'll use a stable reference to the
				// same empty options avoid unnecessary re-renders:
				return from(config) ?? use.withoutAds;
			} catch (error) {
				Core.log.error('CONFIGURATION', 'Prebid', 'Config', 'Options could not be created', {
					error,
				});
			}

			return use.withoutAds;
		}, [enabled, ready, config]);
	}

	export namespace use {
		/** When options are not provided, use `null`. */
		export const withoutAds = null;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		export type withoutAds = typeof withoutAds;

		export function isReady() {
			return Boolean(Api.use());
		}
	}
}
